import { api } from '@shared/services/api';
import { authEmitter, AUTH_EVENTS } from '@shared/services/auth/events';
import { CartEmitter, CART_EVENTS } from '@services/cart/events';
import { store } from '@/store';

function loadDataToStore() {
  if (!store.getters['nyContest/nyEnabled']) return Promise.resolve({});
  if (!store.getters.isAuthorized) {
    return Promise.resolve({});
  }

  return api.lavka.fetchNYCompetitionMarathonRating().then(data => {
    const rating = data.data.rating || [];
    const userRank = data.data.userRank || {};

    store.commit(
      'nyContest/SET_WINNERS_LIST',
      rating.map(v => ({ place: v.rank, score: v.scores, phone: v.phone }))
    );
    store.commit('nyContest/SET_CURRENT_USER', {
      place: userRank.rank,
      score: userRank.scores,
    });
  });
}

function init() {
  if (!store.getters['nyContest/nyEnabled']) return;
  authEmitter.on(AUTH_EVENTS.ON_AUTH, () => {
    loadDataToStore();
  });

  CartEmitter.on(CART_EVENTS.ON_PAYMENT_DONE, () => {
    loadDataToStore();
  });
}

export const NyContestService = {
  init,
  loadDataToStore,
};
