<template>
  <SuggestionsPage
    :is-desktop="$isDesktop"
    :is-web-view="$isWebView"
    :loading="loading"
    :products="suggestions"
    :added-from="PRODUCT_ADDED.SUGGESTIONS_PAGE"
  />
</template>

<script>
import SuggestionsPage from '@/views/suggestions/SuggestionsPage.vue';
import { mapGetters } from 'vuex';
import { PRODUCT_ADDED } from '@shared/config/product';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'SuggestionPageView',
  components: {
    SuggestionsPage,
  },
  data: () => ({
    loading: false,
    products: [],
    PRODUCT_ADDED,
  }),
  computed: {
    ...mapGetters('crossSell', ['suggestions']),
  },
  mounted() {
    //TODO: нужно будет дополнить если появятся другие входные точки
    Analitycs.logEvent(EVENTS.VIEWED_ALL_SUGGESTIONS, { from: 'cart' });
  },
  methods: {},
};
</script>
