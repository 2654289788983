<template>
  <div class="Story" :class="{ Story_watched: watched }" v-on="listeners">
    <img
      v-if="!(videoMobile || videoDesktop)"
      v-img-proxy="{
        url: $isDesktop ? imageDesktop : imageMobile,
        size: $isDesktop ? 's:188:172' : 's:109:132',
        as: 'webp',
      }"
      alt=""
      class="Story__img"
    />
    <video
      v-else
      loop
      muted
      autoplay
      playsinline
      style="object-fit: cover"
      class="Story__video"
      :poster="$isDesktop ? imageDesktop : imageMobile"
      :src="$isDesktop ? videoDesktop : videoMobile"
    />
    <!--
      iOS не смотря ни на что при удержании на картинке
      увеличивает её и показывает какое-то превью.
      Перекроем её невидимым слоем
    -->
    <div class="Story__imgOverlay" />
    <div class="Story__text">
      <span v-html="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Story',
  props: {
    code: {
      type: String,
      default: '',
    },
    textRu: {
      type: String,
      default: '',
    },
    textKk: {
      type: String,
      default: '',
    },
    imageMobile: {
      type: String,
      default: '',
    },
    imageDesktop: {
      type: String,
      default: '',
    },
    videoMobile: {
      type: String,
      default: '',
    },
    videoDesktop: {
      type: String,
      default: '',
    },
    watched: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showStories: false,
  }),
  computed: {
    listeners() {
      const that = this;

      return {
        ...that.$listeners,
      };
    },
    text() {
      const prop = this.$i18n.locale === 'ru' ? 'textRu' : 'textKk';
      return this[prop];
    },
  },
  eventBusEvents: {
    eb_on_reconnect_to_app() {
      setTimeout(() => {
        this.$log('Story.vue onReconnectToApp');
        if (this.$refs.video) {
          this.$refs.video.play();
        }
      }, 300);
    },
  },
};
</script>

<style scoped>
.Story {
  --width: 109px;
  --desktop-width: 188px;
  position: relative;
  width: var(--width);
  height: 100%;
  padding: 9px 10px 10px 10px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1.5px solid #fdb581;
  cursor: pointer;
  transition: transform 0.3s;
  /**
    На ios из-за применённого transition ломается скролл
    если touchstart был на самом баннере.
    Воспроизводится только на проде (возможно потому, что
    там есть блок "Скидки" с товарами, там при тапе какой-то
    странный глич с картинками).
   */
  will-change: transform;
}

.Story:active {
  transform: scale(0.95);
}

.Story_watched {
  border-color: #d4d3df;
}

.Story__img {
  position: absolute;
  top: 1.5px;
  left: 1.5px;
  object-fit: cover;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border-radius: 14px;
  background: #d4d3df;
}

.Story__video {
  position: absolute;
  top: 1.5px;
  left: 1.5px;
  object-fit: cover;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border-radius: 14px;
  background: #d4d3df;
}

/*
  На старом ios у видео появляется иконка-треугольник
*/
.Story__video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}
.Story__video::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}
.Story__video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.Story__imgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Story__text {
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.07px;
}

@media (min-width: 768px) {
  .Story {
    --width: var(--desktop-width);
    border-width: 2px;
    border-radius: 24px;
    padding: 19px 20px 20px 20px;
  }

  .Story__img {
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 20px;
  }

  .Story__video {
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 20px;
  }

  .Story__text {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
