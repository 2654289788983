<template>
  <MainLayout
    class="text-sirius-black-100"
    with-checkout-reminder
    with-bottom-toolbar
    :sticky-header="!$isWebView && hasReferralPlank"
  >
    <template v-if="$isWebView" #header>
      <CatalogHeader
        @onBackClick="onBackClick"
        @onClose="onClose"
        @onSearch="goToSearch"
        @onLoyalty="onLoyaltyClick"
        @onFavorite="goFavorite"
      />
    </template>
    <!-- <template v-else-if="!$isDesktop" #header>
      <WebMobileHeaderMain />
    </template> -->
    <template v-if="!$isWebView" #header-wrapper>
      <header
        class="main-header-desk"
        :class="[hasReferralPlank ? '--sticky' : '--fixed']"
      >
        <DeskHeader v-if="$isDesktop" />
        <WebMobileHeaderMain v-else />
      </header>
    </template>

    <div class="flex flex-col container mt-4 md:mt-16" style="max-width: 988px">
      <NoAddressReminderContainer v-if="!$isDesktop" />
      <AddressConfirmationPopoverWrapper />

      <UiRippleLoader
        v-if="!$store.getters['catalog/hasMainCategories']"
        class="mx-auto mt-16"
      />
      <div v-else class="md:mt-16">
        <SimpleCategories
          :categories="$store.state.catalog['simpleCategories']"
        />
      </div>
    </div>

    <UpButton />
    <ConfirmClose @confirm="closeMiniApp" @close="popup.hide()" />
  </MainLayout>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { AuthService } from '@shared/services/auth';
import { RahmetApp } from '@shared/RahmetApp';
import SimpleCategories from '@/components/SimpleCategories/SimpleCategories.vue';
import WebMobileHeaderMain from '@/components/header/WebMobileHeaderMain.vue';
import DeskHeader from '@/components/header/desk-header/DeskHeader.vue';
import CatalogHeader from '@/components/header/CatalogHeader.vue';
import MainLayout from '@layout/MainLayout.vue';

export default {
  name: 'CatalogView',
  components: {
    WebMobileHeaderMain,
    SimpleCategories,
    CatalogHeader,
    MainLayout,
    DeskHeader,
    AddressConfirmationPopoverWrapper: () =>
      import('@/views/main/components/AddressConfirmationPopoverWrapper.vue'),
    NoAddressReminderContainer: () =>
      import('@components/address/NoAddressReminderContainer.vue'),
    ConfirmClose: () =>
      import('@shared/components/confirm-close/ConfirmClose.vue'),
    UpButton: () => import('@shared/components/product/UpButton.vue'),
    UiRippleLoader: () => import('@ui/UiRippleLoader.vue'),
  },
  inject: ['popup'],
  methods: {
    onBackClick() {
      this.$router.back();
    },
    closeMiniApp() {
      Analitycs.logEvent(EVENTS.CROSS_EXIT, { from: 'main' });
      RahmetApp.hapticSelection();
      RahmetApp.backToRahmetApp();
    },
    onClose() {
      RahmetApp.hapticSelection();
      if (this.$store.getters['cart/cartSize']) {
        Analitycs.logEvent(EVENTS.CROSS_EXIT_CONFIRM);
        this.popup.show('confirm-close-cart');
        return;
      }

      this.closeMiniApp();
    },
    goFavorite() {
      RahmetApp.hapticSelection();
      this.$router.push({
        name: 'favorites',
        params: { from: 'main' },
      });
    },
    goToSearch() {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.SEARCH_CLICKED, { page: 'main' });
      this.$router.push({ name: 'search' });
    },
    onLoyaltyClick() {
      if (this.$store.getters['isAuthorized']) {
        this.$eventBus.emit('eb_open_loyalty_info_modal', {
          count: this.$store.getters['loyalty/count'],
        });
      } else {
        AuthService.authorize().then(() => {
          this.$store.dispatch('INIT_AUTHORIZED_FLOW');
        });
      }
    },
  },
};
</script>
