<template>
  <section class="ContestSponsor">
    <div class="ContestSponsor__container">
      <div class="ContestSponsor__inner">
        <div class="ContestSponsor__title">
          <p class="md:text-xl font-bold mb-2 md:mb-4">{{ $t('title') }}</p>
          <img
            class="ContestSponsor__logo1"
            :src="logo1"
            width="290"
            height="31"
            alt="hofman"
          />
          <div class="ContestSponsor__star"></div>
        </div>
        <div class="ContestSponsor__title">
          <p class="md:text-xl font-bold mb-2 md:mb-4">
            {{ $t('partners') }}
          </p>
          <div class="flex items-center space-x-4">
            <img
              class="ContestSponsor__logo2"
              :src="logo2"
              width="95"
              height="30"
              alt="hofman"
            />
            <img
              class="ContestSponsor__logo3"
              :src="logo3"
              width="159"
              height="20"
              alt="hofman"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContestSponsor',
  components: {},
  data() {
    return {
      logo1: new URL(
        '@/views/ny-2024-contest/components/ContestSponsor/images/logo-1.svg',
        import.meta.url
      ).href,
      logo2: new URL(
        '@/views/ny-2024-contest/components/ContestSponsor/images/logo-2.svg',
        import.meta.url
      ).href,
      logo3: new URL(
        '@/views/ny-2024-contest/components/ContestSponsor/images/logo-3.svg',
        import.meta.url
      ).href,
    };
  },
  i18n: {
    messages: {
      ru: {
        title: 'Генеральный партнер',
        partners: 'Партнеры',
      },
      kk: {
        title: 'Бас серіктес',
        partners: 'Серіктестер',
      },
    },
  },
};
</script>

<style scoped>
.ContestSponsor {
  color: #242424;
}

.ContestSponsor__container {
  width: calc(100% - 32px);
  max-width: 988px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ContestSponsor__inner {
  @apply flex justify-center items-center relative flex-col gap-y-6
    md:flex-row md:gap-x-12 md:gap-y-0;
}

.ContestSponsor__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #88bcb1;
}
.ContestSponsor__star {
  background: url('./images/star.png') center center no-repeat;
  background-size: contain;
  position: absolute;
  left: -40px;
  top: -24px;
  width: 60px;
  height: 64px;
}
@media (min-width: 768px) {
  .ContestSponsor__star {
    left: -132px;
    top: -24px;
    width: 96.04px;
    height: 102.9px;
  }
}
@media (max-width: 768px) {
  .ContestSponsor__logo1 {
    width: 261px;
    height: 27.067px;
  }
  .ContestSponsor__logo2 {
    width: 86px;
    height: 28px;
  }
  .ContestSponsor__logo3 {
    width: 143px;
    height: 20px;
  }
}
</style>
