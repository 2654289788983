<template>
  <p class="ReminderText">
    <span v-html="text"></span>
    <span v-if="hasAdditionalScore && cartScore < 5" class="ReminderText__add">
      {{ $t('add') }}
    </span>
  </p>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReminderText',
  computed: {
    ...mapGetters('nyContest', ['cartScore', 'hasAdditionalScore']),
    text() {
      if (!this.cartScore) return this.$t('default');

      let emoji = '☺️';
      if (this.cartScore > 1 && this.cartScore < 5) emoji = '😍';
      else if (this.cartScore >= 5) emoji = '🎉';

      return (
        emoji +
        ' ' +
        this.$t('youGet', {
          score: this.$tc('scoreCount', this.cartScore),
        })
      );
    },
  },
  i18n: {
    messages: {
      ru: {
        results: '🎅🏼 Результаты розыгрыша призов',
        default: '🎅🏼 получайте баллы за заказы от 3000 ₸',
        youGet: `вы получите {score} за этот заказ`,

        add: 'получите 5 — при заказе от 5000 ₸',
        scoreCount:
          '0&nbsp;баллов | {n}&nbsp;балл | {n}&nbsp;балла | {n}&nbsp;баллов',
      },
      kk: {
        results: '🎅🏼 Сыйлықтар ұтысының нәтижелері',
        default: '🎅🏼 3000 ₸ асатын тапсырыстар үшін ұпай алыңыз',
        youGet: `бұл тапсырыс үшін сіз {score} аласыз`,
        add: '5000 ₸ тапсырыс жасасаңыз - 5 ұпай аласыз',
        scoreCount: '{n}&nbsp;ұпай',
      },
    },
  },
};
</script>

<style scoped>
.ReminderText {
  display: flex;
  justify-content: center;
  gap: 0 8px;
  font-size: 14px;
  line-height: 16px;
  color: #009164;
  font-weight: 500;
}

.ReminderText__add {
  color: #acaaba;
}
</style>
