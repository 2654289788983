<template>
  <div>
    <p
      class="flex items-center font-bold text-sirius-black-100 hover:text-sirius-orange-100 active:text-sirius-orange-300"
      @click="$emit('supportClicked', 'call')"
    >
      <IconFooterPhone class="mr-3" />
      <span>{{ callNumber }}</span>
    </p>
    <div class="mt-5">
      <a
        class="flex items-center font-bold text-sirius-black-100 hover:text-sirius-orange-100 active:text-sirius-orange-300"
        target="_blank"
        @click="$emit('supportClicked', 'whatsapp')"
      >
        <IconFooterWA class="mr-3" />
        +{{ whatsAppNumber }}
      </a>
      <div class="flex items-center">
        <div class="w-6 h-6 mr-3"></div>
        <p class="text-sirius-gray-700">
          {{ $t('writeWH') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconFooterPhone from '@shared/components/icons/IconFooterPhone.vue';
import IconFooterWA from '@shared/components/icons/IconFooterWA.vue';

import { i18n } from '../utils';
import { SupportService } from '@services/support';

export default {
  name: 'FooterDeskView',
  components: {
    IconFooterPhone,
    IconFooterWA,
  },
  data() {
    return {
      callNumber: `+${SupportService.formattedSupportNumber('call')}`,
      whatsAppNumber: SupportService.formattedSupportNumber('whatsapp'),
    };
  },
  i18n,
};
</script>

<style></style>
