import { rFetchWarehouseByCoords } from '@shared/services/api/apis';
import { ERR_NO_DELIVERY } from '@shared/config/delivery';
import { DeliveryConfigService } from '@services/delivery-config';
import { replaceTime } from '@shared/utils/replace-time';
import { eventBus } from '@/utils/plugins/event-bus';
import { CityService } from '@services/city';
import { isEmployee } from './utils';
import { rGetPredictionTime } from '@shared/services/api/requestsV2/prediction';
import { $log } from '@/utils/plugins/logger';
import { DevConfigs } from '@/utils/dev-config';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { closureReasonAdapter } from '@services/delivery/lib/adapter-closure-reason';
import { DEFAULT_CITY } from '@services/city/constants';

export const DeliveryModule = {
  namespaced: true,
  state: {
    warehouse: null,
    isFailedToFindWarehouse: false,
    isWarehouseOpen: true,
    openingTime: '8:00:00',
    closingTime: '23:00:00',
    expectedDeliveryTimeData: null,
  },
  getters: {
    isOutOfDeliveryZone(state) {
      return !state.isFailedToFindWarehouse && state.warehouse === null;
    },
    warehouseCoords(state) {
      return state.warehouse
        ? {
            lat: state.warehouse.lat,
            long: state.warehouse.long,
          }
        : null;
    },
    warehouseId(state, getters, rootState, rootGetters) {
      const city = (rootGetters && rootGetters['currentCity']) || DEFAULT_CITY;
      return state.warehouse?.id || city.defaultWarehouseId;
    },
    warehouse(state) {
      return state.warehouse;
    },
    isWarehouseOpen(state) {
      return state.isWarehouseOpen;
    },
    openingTime(state) {
      return state.openingTime;
    },

    isWarehouseGuy(state, getters, rootState, rootGetters) {
      const { warehouse } = state;
      const deliveryAddress = rootGetters['user/deliveryAddress'];
      return isEmployee(warehouse, deliveryAddress);
    },
    /**
     * минчек
     */
    originalUserMinAmount(state) {
      return (state.warehouse && state.warehouse.order_min_amount) || 0;
    },
    /**
     * сурж
     */
    originalMinAmount(state) {
      return (state.warehouse && state.warehouse.min_amount) || 0;
    },
    forceMinAmount(state) {
      return (state.warehouse && state.warehouse.force_min_amount) || 0;
    },
    originalMinCheck(state, getters) {
      return Math.max(getters.originalUserMinAmount, getters.originalMinAmount);
    },
    userMinAmount(state, getters) {
      if (getters.isWarehouseGuy) return 0;

      return getters.originalUserMinAmount;
    },
    minAmount(state, getters, rootState, rootGetters) {
      const isTestWarehouse = [
        1, 6, 8, 9, 11, 12, 13, 19, /* Астана: */ 15, 16, 17,
      ].includes(getters.warehouseId);
      if (isTestWarehouse) return 0;

      const ordersCount = rootGetters['user/userOrdersCount'];

      if (getters.isWarehouseGuy) return 0;
      if (ordersCount < 2) return 0;
      return getters.originalMinAmount;
    },
    minCheck(state, getters) {
      return Math.max(getters.userMinAmount, getters.minAmount);
    },

    closingTime(state) {
      return state.closingTime;
    },
    expectedDeliveryTime(state) {
      const data = state.expectedDeliveryTimeData;
      if (!data) return null;

      return typeof data === 'string'
        ? data
        : `${data.delivery_time_from} - ${data.delivery_time_to}`;
    },
    // возвращает source_type для getDeliveryTimePrediction()
    sourceType(state) {
      const data = state.expectedDeliveryTimeData;
      if (!data) {
        return 'default';
      }
      return data.source_type;
    },
    polygonId(state) {
      return state.warehouse?.polygon_id || null;
    },
    closureReason(state) {
      return closureReasonAdapter(state.warehouse?.polygon_closure_reason);
    },
  },
  mutations: {
    SET_EXPECTED_DELIVERY_TIME_DATA(state, data) {
      if (data !== undefined) {
        state.expectedDeliveryTimeData = data;
      } else {
        console.warn('Данные не были переданы, оставляем состояние неизменным');
      }
    },
    SET_WAREHOUSE(state, newVal) {
      let oldVal = state.warehouse;
      state.warehouse = newVal;

      if (newVal?.id !== oldVal?.id) {
        eventBus.emit('eb_on_warehouse_change', {
          newVal: newVal,
          oldVal: state.warehouse,
        });
      }
    },
    UPDATE_WAREHOUSE(state, value) {
      state.warehouse = {
        ...state.warehouse,
        ...value,
      };
    },
    SET_FAILURE_STATE(state, value) {
      state.isFailedToFindWarehouse = value;
    },
    SET_IS_WAREHOUSE_OPEN(state, value) {
      state.isWarehouseOpen = value;
    },
    SET_OPENING_TIME(state, value) {
      state.openingTime = value;
    },
    SET_CLOSING_TIME(state, value) {
      state.closingTime = value;
    },
    RESET_CLOSURE_REASON(state) {
      state.closureReason = null;
    },
    SET_CLOSURE_REASON(state, closureReason) {
      state.closureReason = closureReasonAdapter(closureReason);
    },
  },
  actions: {
    FIND_WAREHOUSE(ctx) {
      const DEFAULT_ERROR_MESSAGE = 'Не удалось определить зону доставки';

      if (ctx.rootState.user.deliveryAddress == null) {
        // Непонятно, почему ошибка — это нормальный случай, если адреса нет
        // return Promise.reject(
        //   new Error('Не удалось определить адрес доставки')
        // );
        return Promise.resolve();
      }
      const { lat, long } = ctx.rootState.user.deliveryAddress;
      return Promise.resolve()
        .then(() => {
          const coords = { lat, long };
          const { isAuthorized } = ctx.rootGetters;

          return rFetchWarehouseByCoords(coords, isAuthorized);
        })
        .then(warehouse => {
          return {
            warehouse: warehouse.data,
          };
        })
        .then(res => {
          if (res.warehouse == null) {
            throw new Error(ERR_NO_DELIVERY);
          }

          ctx.commit('SET_WAREHOUSE', res.warehouse);

          ctx.commit('SET_FAILURE_STATE', false);

          ctx.dispatch('SET_OPEN_TIME');

          ctx.dispatch('UPDATE_IS_WAREHOUSE_OPEN');
        })
        .then(() => {
          return DeliveryConfigService.loadConfig();
        })
        .catch(err => {
          const noDelivery = err.message === ERR_NO_DELIVERY;
          ctx.commit('SET_WAREHOUSE', null);
          ctx.commit('SET_FAILURE_STATE', !noDelivery);
          return noDelivery
            ? Promise.reject(err)
            : Promise.reject(new Error(DEFAULT_ERROR_MESSAGE));
        });
    },
    FIND_WAREHOUSE_IF_NEEDED(ctx) {
      return ctx.state.isFailedToFindWarehouse
        ? ctx.dispatch('FIND_WAREHOUSE')
        : Promise.resolve();
    },
    SET_IS_WAREHOUSE_OPEN_TO_TRUE(ctx) {
      ctx.commit('SET_IS_WAREHOUSE_OPEN', true);
    },
    UPDATE_IS_WAREHOUSE_OPEN(ctx) {
      const { warehouse } = ctx.state;
      if (!warehouse) return;

      const now = new Date();
      const openDateTime = replaceTime(warehouse.opens_at);
      const tempDate = replaceTime(warehouse.closes_at);
      const closeDateTime = new Date(
        tempDate.setMinutes(tempDate.getMinutes())
      );

      let { polygon_closure_reason: closureReason } = warehouse;

      let isOpenTime = now >= openDateTime && now <= closeDateTime;

      if (isOpenTime && closureReason) {
        ctx.commit('SET_IS_WAREHOUSE_OPEN', false);
      } else if (isOpenTime) {
        ctx.commit('SET_IS_WAREHOUSE_OPEN', true);
      } else {
        ctx.commit('SET_IS_WAREHOUSE_OPEN', false);
      }
    },
    SET_OPEN_TIME(ctx) {
      const { warehouse } = ctx.state;

      if (warehouse && warehouse.closes_at && warehouse.opens_at) {
        ctx.commit('SET_OPENING_TIME', warehouse.opens_at);
        ctx.commit('SET_CLOSING_TIME', warehouse.closes_at);
      }
    },

    FETCH_EXPECTED_DELIVERY_TIME(ctx) {
      const deliveryAddress = ctx.rootGetters['user/deliveryAddress'];
      const originalMinAmount = ctx.getters.originalMinAmount;
      const city_id = CityService.getCity();
      const warehouse_id = ctx.state.warehouse?.id;
      const lat = deliveryAddress?.lat;
      const lon = deliveryAddress?.long;

      // Дефолтные временные рамки
      const defaultTime = { delivery_time_from: 13, delivery_time_to: 17 };
      const distantTime = { delivery_time_from: 30, delivery_time_to: 40 };
      const minAmountTime = { delivery_time_from: 20, delivery_time_to: 30 };

      let expected = ctx.state.warehouse?.expected_delivery_time || '0';

      const { isDistant } = ctx.rootState.paidOrder;

      // склады, на которых работает сервис prediction
      const predictionWarehouses = [1, 2, 3, 5, 7, 9, 11, 19, 8, 10, 12, 14];

      if (!isDistant && predictionWarehouses.includes(warehouse_id)) {
        return rGetPredictionTime(
          city_id,
          warehouse_id,
          lat,
          lon,
          originalMinAmount
        )
          .then(data => {
            if (DevConfigs.isDev) {
              $log('Prediction time: ', JSON.stringify(data.data));
            }

            const currentData = ctx.state.expectedDeliveryTimeData;

            if (
              !currentData ||
              data.data.delivery_time_from !== currentData.delivery_time_from ||
              data.data.delivery_time_to !== currentData.delivery_time_to
            ) {
              Analitycs.logEvent(EVENTS.PREDICTED_DELIVERY_TIME, {
                delivery_time_from: data.data.delivery_time_from,
                delivery_time_to: data.data.delivery_time_to,
                source_type: data.data.source_type,
              });
            }

            ctx.commit('SET_EXPECTED_DELIVERY_TIME_DATA', data.data);
          })
          .catch(error => {
            ctx.commit('SET_EXPECTED_DELIVERY_TIME_DATA', {
              ...defaultTime,
              source_type: 'default',
            });
            throw new Error(
              `Ошибка при получении времени доставки: ${error.message}`
            );
          });
      } else {
        if (expected.includes('-') && !expected.includes(' - ')) {
          expected = expected.replace('-', ' - ');
        }

        let finalTime = defaultTime; // Начальное значение

        // Логика выбора времени доставки
        if (expected !== '0') {
          finalTime =
            isDistant &&
            parseInt(expected.split(' - ')[1]) < distantTime.delivery_time_to
              ? distantTime
              : expected;
        } else if (isDistant) {
          finalTime = distantTime;
        } else if (originalMinAmount) {
          finalTime = minAmountTime;
        }
        ctx.commit('SET_EXPECTED_DELIVERY_TIME_DATA', finalTime);
      }
    },
  },
};
