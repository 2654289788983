<template>
  <CommercialBanners
    ref="commercialBanners"
    v-bind="{
      banners,
      desktopMaxPerScreen,
      showButtonOnEachSlide: true,
    }"
    v-on="{
      onBannerClick,
      onStoryClick,
      onStoryEnd,
      onStoriesClose,
      onCategoryButtonClick,
    }"
  />
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import CommercialBanners from '@pure-ui/components/CommercialBanners/CommercialBanners.vue';
import { BannersService } from '@services/banners';

export default {
  name: 'CommercialBannersWrapper',
  components: {
    CommercialBanners,
  },
  props: {
    desktopMaxPerScreen: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    banners() {
      return BannersService.shuffle(this.$store.getters['banners/banners']);
    },
  },
  mounted() {
    const banners = this.$store.getters['banners/banners'];
    if (banners.find(banner => banner.code === 'referral')) {
      Analitycs.logEvent(EVENTS.REF_BANNER_VIEWED);
    }

    let queryStory = this.$route.query.story;
    if (queryStory) {
      if (banners.find(banner => banner.code === queryStory)) {
        this.$refs.commercialBanners.show(queryStory, true);
      }
    }
  },
  methods: {
    show(code) {
      this.$refs.commercialBanners.show(code);
    },
    onStoryClick(banner) {
      Analitycs.logEvent(EVENTS.BANNER_CLICK, {
        code: banner.code,
      });
    },
    onBannerClick(banner) {
      Analitycs.logEvent(EVENTS.BANNER_CLICK, {
        code: banner.code,
      });
      this.$router.push(banner.url);
    },
    onCategoryButtonClick({ banner, index }) {
      Analitycs.logEvent(EVENTS.STORIES_BUTTON_CLICK, {
        code: banner.code,
        url: banner.url,
        index,
      });
      if (banner.url) {
        this.$router.push(banner.url);
      }
    },
    onStoryEnd(banner) {
      BannersService.setWatched(banner);
      this.banners.find(v => v.code === banner.code).watched = true;
    },
    onStoriesClose(event) {
      if (event.method === 'onCategoryButtonClick') {
        Analitycs.logEvent(EVENTS.STORIES_CLOSE, {
          method: 'go_to_category_button',
          index: event.index,
          code: event.code,
          categoryId: event.banner.url,
        });
      } else {
        Analitycs.logEvent(EVENTS.STORIES_CLOSE, event);
      }
    },
  },
};
</script>
