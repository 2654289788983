<template>
  <div class="ContestRules">
    <div class="ContestRules__container">
      <NyPageSubtitle id="ny-rules">{{ $t('contestRules') }}</NyPageSubtitle>

      <ul>
        <li
          v-for="(rule, index) in $t('rules', { returnObjects: true })"
          :key="'rule' + index"
          v-html="rule"
        ></li>
      </ul>

      <UiButton
        class="ContestRules__btn"
        :height="$isDesktop ? 'xl' : 'lg'"
        :round="$isDesktop ? '3xl' : '2xl'"
        size="none"
        variant="primary"
        @click="$emit('onReadFull')"
      >
        <div class="w-6"></div>
        {{ $t('readFull') }} <IconArrow />
      </UiButton>

      <div class="ContestRules__img"></div>
    </div>
  </div>
</template>

<script>
import NyPageSubtitle from '@/views/ny-2024-contest/components/NyPageSubtitle/NyPageSubtitle.vue';
import UiButton from '@ui/UiButtonNew.vue';
import IconArrow from './componets/IconArrow.vue';
export default {
  name: 'ContestRules',
  components: {
    UiButton,
    NyPageSubtitle,
    IconArrow,
  },
  i18n: {
    messages: {
      ru: {
        readFull: 'Перейти ко всем правилам',
        contestRules: 'Правила розыгрыша',
        rules: [
          `Участвовать могут пользователи, совершившие заказ на&nbsp;сумму от&nbsp;3&nbsp;000 тенге в&nbsp;период с&nbsp;14&nbsp;декабря 2024&nbsp;года до&nbsp;14&nbsp;января 2025&nbsp;года;`,
          `Победителями становятся участники акции, вошедшие в&nbsp;ТОП-50, то&nbsp;есть набравшие наибольшее количество баллов в&nbsp;период проведения акции;`,
          `В розыгрыше дополнительного приза участвуют пользователи с 50+ баллами, но не вошедшие в ТОП-50;`,
          `Баллы нельзя переносить с&nbsp;аккаунта на&nbsp;аккаунт;`,
          `Сотрудники ChocoFamily, их супруги и&nbsp;близкие родственники не&nbsp;могут участвовать в розыгрыше;`,
          `Организатор вправе изменить условия, срок действия и&nbsp;механику розыгрыша;`,
          `Организатор вправе поменять оглашённые призы на&nbsp;другие, эквивалентные по&nbsp;стоимости;`,
          `Организатор имеет право аннулировать результаты розыгрыша в&nbsp;случае обнаружения признаков мошенничества;`,
          `Доставка в Астану будет осуществляться при помощи частных компаний. Сроки и условия доставки будут согласовываться индивидуально с каждым победителем после объявления результатов;`,
          `При&nbsp;несоблюдении условий участия в&nbsp;розыгрыше, выигравший участник будет дисквалифицирован, а&nbsp;приз будет предоставлен следующему участнику в&nbsp;рейтинге, не&nbsp;вошедшему в&nbsp;ТОП–50.`,
        ],
      },
      kk: {
        readFull: 'Барлық ережелерге өту',
        contestRules: 'Ойын ережесі',
        rules: [
          `Науқанға 2024&nbsp;жылғы 14&nbsp;желтоқсан мен 2025&nbsp;жылғы 14&nbsp;қаңтар аралығында 3000&nbsp;теңгеден басталатын сомаға тапсырыс жасаған қолданушылар қатыса алады;`,
          `Науқанда ТОП-50-ке кіргендер, яғни науқан өткізілген кезеңде мейлінше көбірек ұпай жинағандар жеңімпаз болып танылады;`,
          `Қосымша сыйлық ұтыс ойнына үздік 50-ге кірмеген, бірақ 50 немесе одан да көп ұпай жинаған қатысушылар арасында өтеді;`,
          `Ұпайларды бір аккаунттан екінші аккаунтқа ауыстыруға болмайды;`,
          `ChocoFamily қызметкерлері, олардың жұбайы мен жақын туыстары жүлде ойынына қатыса алмайды;`,
          `Ұйымдастырушы ұтыс ойынының шарттарын, әрекет ету мерзімін және механизмін өзгерту құқығына ие;`,
          `Ұйымдастырушы жарияланған сыйлықтарды басқа, құны бойынша тең сыйлықтарға ауыстыру құқығына ие;`,
          `Ұйымдастырушы ұтыс ойынының нәтижелерін бұрмалау белгілері табылған жағдайда жоюға құқылы;`,
          `Астанаға жеткізу жеке компаниялар арқылы жүзеге асырылады. Жеткізу мерзімдері мен шарттары әрбір жеңімпазбен жеке келісім бойынша анықталады;`,
          `Ұтыс ойынының қатысу шарттары сақталмаған жағдайда, жеңіске жеткен қатысушы дисквалификацияланып, сыйлық келесі рейтингте тұрған қатысушыға беріледі, ТОП–50 тізіміне кірмеген.`,
        ],
      },
    },
  },
};
</script>

<style scoped>
.ContestRules {
  background: #e9eeed;
}

.ContestRules__container {
  position: relative;
  width: calc(100% - 32px);
  max-width: 984px;
  margin: auto;
  padding: 0 21px;
}

.ContestRules ul {
  padding-top: 16px;
  color: #242424;
}

.ContestRules li {
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.ContestRules li::before {
  content: '•';
  position: absolute;
  top: 0;
  left: -16px;
}

.ContestRules li + li {
  margin-top: 16px;
}
.ContestRules__btn {
  @apply mt-8 md:mt-32;
  font-weight: 700;
  width: 100%;
}

.ContestRules__img {
  position: absolute;
  top: 58px;
  right: -32px;
  width: 69px;
  height: 69px;
  background: url('@/assets/images/ny-2024-contest/snow.png') center center
    no-repeat;
  background-size: contain;
}
@media screen and (min-width: 768px) {
  .ContestRules ul {
    columns: 2;
    column-gap: 64px;
    padding-top: 32px;
  }

  .ContestRules__img {
    position: absolute;
    top: 20px;
    left: -100px;
    width: 100px;
    height: 100px;
  }

  .ContestRules__btn {
    font-size: 20px;
    line-height: 24px;
    border-radius: 24px;
    max-width: 332px;
  }
}
</style>
