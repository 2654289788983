<template>
  <CustomProductsList
    v-if="showCategorySlider"
    :products="specialCategoryList"
  />
</template>

<script>
import CustomProductsList from '@/views/main/components/CustomProductsList.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CustomProductListWrapper',
  components: { CustomProductsList },
  computed: {
    ...mapGetters('specialCategory', ['specialCategoryList']),
    ...mapGetters('delivery', ['warehouseId']),
    showCategorySlider() {
      return (
        this.warehouseId === 5 &&
        !this.$isWebView &&
        this.specialCategoryList.length > 0
      );
    },
  },
};
</script>

<style scoped></style>
