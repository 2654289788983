import { store } from '@/store';
import { CITIES, DEFAULT_CITY } from './constants';
import { createStorage } from '@shared/services/storage';
import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';

const KEY = 'city::defaultId';
let storage = createStorage(window.localStorage);
let currentCityId = storage.parse(KEY, DEFAULT_CITY.id) || DEFAULT_CITY.id;

const findCity = id => Object.values(CITIES).find(c => c.id === +id);

/**
 * @returns {Number} id of city
 */
function getCity() {
  return currentCityId;
}

function getCityData() {
  return Object.values(CITIES).find(c => c.id === getCity());
}

function findCityIdByName(name) {
  let city = Object.values(CITIES).find(c => c.name === name);
  return city ? city.id : getCity();
}

function findCityById(id) {
  return findCity(id) || DEFAULT_CITY;
}

function isDefaultCitySelected() {
  return storage.parse(KEY, null) !== null;
}

function setCity(id, from = '') {
  $log('CityService ', { id, from, currentCityId }, 'setCity');

  let city = findCity(currentCityId);

  if (id && +id !== city.id) {
    let found = findCity(id);
    if (found) city = found;
  }

  const oldCityId = currentCityId;
  const newCityId = city.id;
  currentCityId = newCityId;

  storage.set(KEY, currentCityId);
  store.commit('SET_CURRENT_CITY_ID', currentCityId);

  if (oldCityId !== newCityId || !isDefaultCitySelected()) {
    eventBus.emit('eb_on_city_changed', { ...city, from });
  }
}

export const CityService = {
  setCity,
  getCity,
  getCityData,
  findCityById,
  findCityIdByName,
  isDefaultCitySelected,
};
