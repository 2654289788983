<template>
  <UiPopup
    v-if="$isDesktop"
    :show="show"
    :closable="closable"
    @change="v => $emit('change', v)"
    @onOpen="$emit('onOpen')"
    @onClose="$emit('onClose')"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <slot></slot>
    <template #buttons>
      <slot name="buttons"></slot>
    </template>
  </UiPopup>

  <UiBottomSheetWrapper
    v-else
    :show="show"
    :with-title="withTitle"
    :with-buttons="withButtons"
    :with-back-button="withBackButton"
    :is-clamp="isClamp"
    @change="v => $emit('change', v)"
    @onOpen="$emit('onOpen')"
    @onClose="$emit('onClose')"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <slot></slot>

    <template #buttons>
      <slot name="buttons"></slot>
    </template>
  </UiBottomSheetWrapper>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';

export default {
  name: 'UiBottomSheetPopup',
  components: {
    UiBottomSheetWrapper,
    UiPopup,
  },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    closable: {
      type: Boolean,
      default: false,
    },
    withBackButton: {
      type: Boolean,
      default: false,
    },
    withButtons: {
      type: Boolean,
      default: false,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    isClamp: {
      type: Boolean,
      default: true,
    },
  },
  beforeDestroy() {
    this.closeModal();
  },
  methods: {
    closeModal() {
      this.$emit('change', false);
    },
  },
};
</script>
