<template>
  <div class="CurrentScore">
    <div class="CurrentScore__container">
      <ContentBlock
        class="CurrentScore__block"
        :score="score"
        :place="place"
        :participate="participate"
        v-on="$listeners"
      />
      <div class="CurrentScore__mail"></div>
      <div class="CurrentScore__star1"></div>
      <div class="CurrentScore__star2"></div>
    </div>
  </div>
</template>

<script>
import ContentBlock from './components/ContentBlock/ContentBlock.vue';

export default {
  name: 'CurrentScore',
  components: {
    ContentBlock,
  },
  props: {
    score: {
      type: Number,
      default: 0,
    },
    place: {
      type: Number,
      default: 0,
    },
    participate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.CurrentScore {
  @apply mx-4;
  /* padding-top: 64px; */
  /* min-height: 520px; */
  background: #e9eeed;
}

.CurrentScore__container {
  @apply w-full;
  position: relative;
  margin: auto;
  max-width: 400px;
}
.CurrentScore__mail {
  position: absolute;
  background: url('./images/cover.png') center center no-repeat;
  background-size: contain;
  width: 134px;
  height: 186px;
  right: 0;
  top: 58px;
}

.CurrentScore__star1 {
  position: absolute;
  background: url('./images/star 1.png') center center no-repeat;
  background-size: cover;
  width: 47px;
  height: 60px;
  right: 57px;
  top: 57px;
}

.CurrentScore__star2 {
  position: absolute;
  background: url('./images/star.png') center center no-repeat;
  background-size: cover;
  width: 28px;
  height: 40px;
  right: 24px;
  top: 15px;
}

@media screen and (min-width: 768px) {
  .CurrentScore__container {
    max-width: 1188px;
  }

  .CurrentScore__block {
    max-width: 1188px;
  }

  .CurrentScore__heart {
    left: calc(100% - 370px);
    top: 62px;
  }
}
</style>
