<template>
  <div class="TableItem">
    <div class="TableItem__place">
      {{ place }}
    </div>
    <div class="TableItem__text">
      <div class="TableItem__score">
        <span class="TableItem__scoreValue">
          {{ score }}
        </span>
        {{ $tc('score', score) }}
      </div>
      <div
        class="TableItem__phone"
        v-html="$t('personNumber', { phone: formattedPhone })"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableItem',
  props: {
    place: {
      type: Number,
      default: 0,
    },
    score: {
      type: Number,
      default: 0,
    },
    phone: {
      type: String,
      default: '',
    },
  },
  computed: {
    formattedPhone() {
      if (this.phone.length === 11) {
        return [
          '+7 *** ***',
          ` ${this.phone.slice(7, 9)}`,
          ` ${this.phone.slice(9, 11)}`,
        ].join('');
      }
      return this.phone;
    },
  },
  i18n: {
    messages: {
      kk: {
        score: 'ұпай',
        personNumber: 'номері {phone}<br/>деген қолданушы',
      },
      ru: {
        score: 'баллов | балл | балла | баллов',
        personNumber: 'пользователь с&nbsp;номером<br/>{phone}',
      },
    },
  },
};
</script>

<style scoped>
.TableItem {
  display: grid;
  grid-template-columns: 52px auto;
  gap: 24px;
}

.TableItem__place {
  background: url('./images/bullet.png') left top / 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  color: #fff;
  width: 52px;
  height: 52px;
}

.TableItem__score {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.TableItem__scoreValue {
  font-size: 24px;
  line-height: 30px;
}

.TableItem__phone {
  color: #7b7a8c;
  font-size: 16px;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .TableItem__phoneValue {
    display: inline;
  }
}
</style>
