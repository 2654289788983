import { isCoffeeAvailable } from '../is-coffee-available';

/**
 * На одном полигоне включена возможность заказа сваренного кофе.
 * Но эти товары бэк отдаёт всегда, независимо от полигона.
 * Поэтому если пз выбрал неподдерживаемый адрес,
 * то эти товары нужно везде скрыть
 */
export function getFilteredProducts(products = []) {
  if (!isCoffeeAvailable()) {
    return products.filter(p => {
      // Проверяем, есть ли в объекте поле product_info
      const isCoffee = p.product_info ? p.product_info.is_coffee : p.is_coffee;
      return !isCoffee;
    });
  }

  return products;
}
