<template>
  <div class="NyBanner">
    <div class="NyBanner__container">
      <router-link
        class="NyBanner__block"
        tag="div"
        :to="{
          name: 'ny-contest-2024',
          query: { from: 'category_page_banner' },
        }"
      >
        <div class="NyBanner__title">
          {{ $t('title') }}
        </div>
        <div class="NyBanner__descr">
          <span v-html="$t('descr')" />
        </div>
        <ArrowIcon v-if="!$isDesktop" class="NyBanner__arrow" />
        <ArrowIconDesktop v-if="$isDesktop" class="NyBanner__arrow" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ArrowIcon from './components/ArrowIcon/ArrowIcon.vue';
import ArrowIconDesktop from './components/ArrowIconDesktop/ArrowIconDesktop.vue';

export default {
  name: 'NyBanner',
  components: {
    ArrowIcon,
    ArrowIconDesktop,
  },
  props: {
    isAdditional: Boolean,
  },
  i18n: {
    messages: {
      ru: {
        title: 'Дополнительный балл',
        descr: 'при покупке товаров этой категории',
      },
      kk: {
        title: 'Қосымша ұпай',
        descr: 'осы категория тауарларын сатып алғандарға',
      },
    },
  },
};
</script>

<style scoped>
.NyBanner {
}
.NyBanner__container {
  overflow: hidden;
  border-radius: 16px;
  background: linear-gradient(146deg, #007f57 -3.68%, #005f3d 89.53%), #fff;
}
.NyBanner__block {
  gap: 16px;
  height: 56px;
  overflow: hidden;
  cursor: pointer;
  padding: 9px 16px;
  overflow: hidden;

  color: white;
  background: url('./images/additional.png') right bottom no-repeat;
  background-size: contain;
}

.NyBanner__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.NyBanner__descr {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: #88bcb1;
}

.NyBanner__arrow {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media screen and (min-width: 768px) {
  .NyBanner__container {
    border-radius: 24px;
  }
  .NyBanner__block {
    height: 64px;
    padding: 20px 0 20px 88px;
    background: url('./images/banner-bg-desk.png') center center no-repeat;
    background-size: cover;
  }

  .NyBanner__title {
    display: inline;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .NyBanner__descr {
    display: inline;
    font-size: 18px;
    line-height: 24px;
    color: #88bcb1;
    margin-left: 16px;
  }
  .NyBanner__arrow {
    top: 20px;
    right: 14px;
  }
}
</style>
