<template>
  <blockquote class="NyBlockquote">
    <slot />
  </blockquote>
</template>

<script>
export default {
  name: 'NyBlockquote',
};
</script>

<style scoped>
.NyBlockquote {
  @apply flex items-center justify-center
  rounded-3xl
  font-medium leading-5 text-white text-center
  md:text-xl md:leading-6 md:font-bold;
  background: url('./images/blocquote-bg.png') center center no-repeat;
  background-size: cover;
  height: 100px;
  padding: 0 42px;
}

@media (min-width: 768px) {
  .NyBlockquote {
    width: 100%;
    padding: 36px 48px;
    height: 160px;
    border-radius: 36px;
    background: url('./images/blocquote-bg-desk.png') center center no-repeat;
    background-size: contain;
  }

  .NyBlockquote__content {
    max-width: 412px;
  }
}
</style>
