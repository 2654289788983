<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 24.5044C19.8014 24.5044 24.5044 19.8014 24.5044 14C24.5044 8.19857 19.8014 3.49561 14 3.49561C8.19857 3.49561 3.49561 8.19857 3.49561 14C3.49561 19.8014 8.19857 24.5044 14 24.5044Z"
      stroke="#A4A2B7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0006 15.7506V13.9999C15.2898 13.9999 16.3349 12.9548 16.3349 11.6656C16.3349 10.3764 15.2898 9.3313 14.0006 9.3313C12.7114 9.3313 11.6663 10.3764 11.6663 11.6656"
      stroke="#A4A2B7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0006 18.6102C13.9683 18.6102 13.9422 18.6363 13.9422 18.6685C13.9422 18.7008 13.9683 18.7269 14.0006 18.7269C14.0328 18.7269 14.0589 18.7008 14.0589 18.6685C14.0589 18.6363 14.0328 18.6102 14.0006 18.6102"
      stroke="#A4A2B7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconInfo',
};
</script>
