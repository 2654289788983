<template>
  <div class="">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <g clip-path="url(#a)">
        <path
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M12 21a9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9"
        ></path>
        <path
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M12.25 17v-5h-1M11.999 8a.25.25 0 1 0 .002.5A.25.25 0 0 0 12 8"
        ></path>
        <circle cx="12" cy="8.25" r=".78" fill="#000"></circle>
      </g>
      <defs>
        <clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z"></path></clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconInfo',
};
</script>
