import { CrossSellService } from '@services/cross-sell';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export const CrossSellModule = {
  namespaced: true,
  state: {
    suggestions: [],
  },
  getters: {
    suggestions(state) {
      return state.suggestions;
    },
  },
  mutations: {
    SET_SUGGESTIONS(state, value) {
      state.suggestions = value;
    },
  },
  actions: {
    FETCH_SUGGESTIONS({ commit, rootGetters }, { product_ids }) {
      const warehouseId = rootGetters['delivery/warehouseId'];
      const amountOfProducts = product_ids.split(',').length;
      const payload = {
        warehouse_id: warehouseId,
        product_ids: product_ids,
      };
      return CrossSellService.getSuggestions(payload).then(data => {
        Analitycs.logEvent(EVENTS.FETCHED_SUGGESTIONS, {
          products: amountOfProducts,
          suggestions: data.data.length,
        });
        commit('SET_SUGGESTIONS', data.data);
      });
    },
  },
};
