/* eslint-disable */

const VERBOSE = 0;

export function log(...args) {
  VERBOSE && console.log(...args);
}

/**
 * Элементы, которые в данный момент отрендерены
 * и видимы
 */
export const els = {};

function getDefaultOffset(elementNames) {
  let offset = 0;
  if (elementNames.includes('bottom-bar') && els['bottom-bar']) {
    offset += 56;
  }
  if (elementNames.includes('go-to-cart-button') && els['go-to-cart-button']) {
    offset += 58;
  }
  if (elementNames.includes('paid-delivery') && els['paid-delivery']) {
    offset += 32;
  }
  if (
    elementNames.includes('open-time-or-no-delivery') &&
    (els['open-time'] || els['no-delivery-reminder'])
  ) {
    offset += 72;
  }
  if (
    elementNames.includes('ny-contest-reminder') &&
    els['ny-contest-reminder']
  ) {
    offset += 32;
  }

  return offset;
}

export function calculate() {
  if (window.innerWidth > 767) return;

  if (els['up-button']) {
    let offset = getDefaultOffset([
      'bottom-bar',
      'go-to-cart-button',
      'paid-delivery',
      'open-time-or-no-delivery',
      'ny-contest-reminder',
    ]);

    els['up-button'].style.transform = `translateY(${-(72 + offset)}px)`;
  }

  if (els['mini-order']) {
    let offset = getDefaultOffset([
      'bottom-bar',
      'go-to-cart-button',
      'paid-delivery',
      'open-time-or-no-delivery',
      'ny-contest-reminder',
    ]);

    els['mini-order'].style.right = els['up-button'] ? `82px` : `16px`;
    els['mini-order'].style.transform = `translateY(${-(72 + offset)}px)`;
  }

  if (els['open-time']) {
    let offset = getDefaultOffset([
      'bottom-bar',
      'go-to-cart-button',
      'paid-delivery',
      'ny-contest-reminder',
    ]);

    els['open-time'].style.bottom = `${offset + 26}px`;
  }

  if (els['no-delivery-reminder']) {
    let offset = getDefaultOffset([
      'bottom-bar',
      'go-to-cart-button',
      'paid-delivery',
      'ny-contest-reminder',
    ]);

    els['no-delivery-reminder'].style.bottom = `${offset + 26}px`;
  }

  if (els['ny-contest-reminder']) {
    let offset = getDefaultOffset([
      'bottom-bar',
      'go-to-cart-button',
      'paid-delivery',
    ]);

    els['ny-contest-reminder'].style.bottom = `${offset - 22}px`;
  }

  VERBOSE && console.log('!!! floating-element [calculate]', els);
}
