<template>
  <ul class="ReviewsList">
    <li class="ReviewsList__item ReviewsList__item_1">
      <EmojiScream class="ReviewsList__svg" />
      <span v-html="$t('text1')" />
    </li>
    <li class="ReviewsList__item ReviewsList__item_2">
      <EmojiHeartEyes class="ReviewsList__svg" />
      <span v-html="$t('text2')" />
    </li>
    <li class="ReviewsList__item ReviewsList__item_3">
      <EmojiSunglasses class="ReviewsList__svg" />
      <span v-html="$t('text3')" />
    </li>
  </ul>
</template>
<script>
import EmojiScream from '@/views/ny-2024-contest/components/TopHero/components/EmojiScream/EmojiScream.vue';
import EmojiHeartEyes from '@/views/ny-2024-contest/components/TopHero/components/EmojiHeartEyes/EmojiHeartEyes.vue';
import EmojiSunglasses from '@/views/ny-2024-contest/components/TopHero/components/EmojiSunglasses/EmojiSunglasses.vue';

export default {
  name: 'ReviewsList',
  components: {
    EmojiScream,
    EmojiHeartEyes,
    EmojiSunglasses,
  },
  i18n: {
    messages: {
      ru: {
        text1: '50&nbsp;ценных призов',
        text2: 'розыгрыш iPhone&nbsp;16&nbsp;pro',
        text3: 'больше заказов&nbsp;— больше&nbsp;шансов выиграть',
      },
      kk: {
        text1: '50 бағалы сыйлық',
        text2: 'iPhone&nbsp;16&nbsp;Pro ойнатылады',
        text3:
          'Тапсырыс көбірек болған сайын, бағалы сыйлықтар ұту мүмкіндігі де көбірек',
      },
    },
  },
};
</script>

<style scoped>
.ReviewsList__item {
  padding: 16px 24px;
  color: #000;
  background: #fff;
  border-radius: 16px;
  background: #fff;
  box-shadow: 1px 4px 24px 0px rgba(110, 83, 52, 0.2);

  color: #242424;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */

  display: grid;
  grid-template-columns: 24px auto;
  gap: 0 12px;
}

.ReviewsList__svg {
  display: inline-block;
}

.ReviewsList__item_1 {
  max-width: 231px;
}

.ReviewsList__item_2 {
  max-width: 260px;
  transform: rotate(-8.1deg);
  position: relative;
  top: -20px;
  left: 47px;
}

.ReviewsList__item_3 {
  max-width: 272px;
  position: relative;
  top: -29px;
  left: 24px;
}
</style>
