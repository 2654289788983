<template>
  <div class="NyCartReminder">
    <div class="NyCartReminder__title" @click="openModal">
      {{ $t('title') }}
      <IconInfo />
    </div>
    <ReminderText
      class="NyCartReminder__text"
      :score="$store.getters['nyContest/cartScore']"
      :user-score="$store.getters['nyContest/currentUserScore']"
    />
  </div>
</template>

<script>
import IconInfo from './components/IconInfo/IconInfo.vue';
import ReminderText from './components/ReminderText/ReminderText.vue';

export default {
  name: 'NyCartReminder',
  components: { IconInfo, ReminderText },
  i18n: {
    messages: {
      ru: {
        title: 'Дед Мороз Рядом',
      },
      kk: {
        title: 'Рядом Аяз Ата',
      },
    },
  },
  methods: {
    openModal() {
      this.$eventBus.emit('eb_open_ny_conditions_modal', {
        from: 'cart-reminder',
      });
    },
  },
};
</script>

<style scoped>
.NyCartReminder__title {
  font-size: 16px;
  line-height: 20px;
  color: #009164;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NyCartReminder__text {
  padding-top: 8px;
}
</style>
