const WHITE_LIST = [
  /lavka(-dev)?\.object\.pscloud\.io/,
  // https://lavka.object.pscloud.io/static/upload/images/lavka/products/1724299848-966.png
  // https://lavka-dev.object.pscloud.io/static/upload/images/lavka/products/1724299848-966.png
];

/**
 * Имеется ли применимое регулярное выражение
 */
const containsWhiteListUrl = url => {
  return WHITE_LIST.some(re => re.test(url));
};

/**
 * Получение формата картинки
 */
const getWishedFormat = as => (as ? `@${as}` : '');

const SCALE = window && window.innerWidth < 768 ? 3 : 2;

/**
 * В проекте используются несколько бакетов: rahmet и parstore.
 * Надо динамически хендлить url в зависимости от бакета
 */
export const getSource = options => {
  let source = options.placeholder || '';

  if (options.url) {
    // убрать для отличных url получение баскета
    if (!containsWhiteListUrl(options.url)) {
      source = options.url;
    } else {
      const urlWrapper = new URL(options.url);
      const parts = [];

      parts.push(import.meta.env.VITE_IMAGE_PROXY);
      parts.push('sig');

      const scaleIt = n => parseInt(n * SCALE);
      if (options.size) {
        let [, w, h] = options.size.split(':');
        w = scaleIt(w);
        h = scaleIt(h);
        parts.push(`s:${w}:${h}`);
      }
      if (options.height) {
        let [, h] = options.height.split(':');
        h = scaleIt(h);
        parts.push(`h:${h}`);
      }
      if (options.width) {
        let [, w] = options.width.split(':');
        w = scaleIt(w);
        parts.push(`w:${w}`);
      }

      parts.push('plain/s3:/');
      parts.push(urlWrapper.hostname.split('.')[0]);
      parts.push(urlWrapper.pathname);

      source = parts.join('/') + getWishedFormat(options.as || '');
    }
  }

  return source;
};
