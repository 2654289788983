<template>
  <div class="NyBanner">
    <div class="NyBanner__container">
      <router-link
        class="NyBanner__block"
        tag="div"
        :to="{ name: 'ny-contest-2024', query: { from: 'main_page_banner' } }"
      >
        <div class="NyBanner__title">
          {{ $t('title') }}
        </div>
        <div class="NyBanner__descr">
          <span v-html="$t('descr')" />
        </div>
        <ArrowIcon v-if="!$isDesktop" class="NyBanner__arrow" />
        <ArrowIconDesktop v-if="$isDesktop" class="NyBanner__arrow" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ArrowIcon from './components/ArrowIcon/ArrowIcon.vue';
import ArrowIconDesktop from './components/ArrowIconDesktop/ArrowIconDesktop.vue';

export default {
  name: 'NyBanner',
  components: {
    ArrowIcon,
    ArrowIconDesktop,
  },
  props: {
    isAdditional: Boolean,
  },
  i18n: {
    messages: {
      ru: {
        title: 'Розыгрыш ценных призов',
        descr:
          'Cовершайте покупки от&nbsp;3000&nbsp;₸ и&nbsp;выигрывайте призы',

        addTitle: 'Дополнительный балл',
        addDescr: 'при покупке товаров этой категории',
      },
      kk: {
        title: 'Бағалы сыйлықтар ойны',
        descr: `
          3000 теңгеден бастап тапсырыс жасап, сыйлықтар ұтып ал
        `,

        addTitle: 'Қосымша ұпай',
        addDescr: 'осы категория тауарларын сатып алғандарға',
      },
    },
  },
};
</script>

<style scoped>
.NyBanner {
}
.NyBanner__container {
  overflow: hidden;
  border-radius: 16px;
  background: linear-gradient(146deg, #007f57 -3.68%, #005f3d 89.53%), #fff;
}
.NyBanner__block {
  display: flex;
  gap: 16px;
  height: 56px;
  overflow: hidden;
  cursor: pointer;
  padding: 18px 0 18px 48px;

  color: white;
  background: url('./images/banner-bg.jpg') center center no-repeat;
  background-size: cover;
}

.NyBanner__title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.NyBanner__descr {
  display: none;
}

.NyBanner__arrow {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media screen and (min-width: 768px) {
  .NyBanner__container {
    border-radius: 24px;
  }
  .NyBanner__block {
    height: 64px;
    padding: 20px 0 20px 88px;
    background: url('./images/banner-bg-desk.png') center center no-repeat;
    background-size: cover;
  }

  .NyBanner__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .NyBanner__descr {
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: #88bcb1;
  }
  .NyBanner__arrow {
    top: 20px;
    right: 14px;
  }
}
</style>
