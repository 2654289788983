export const prizes = [
  {
    place: {
      ru: 'дополнительный розыгрыш',
      kk: 'қосымша ұтыс ойны',
    },
    title: {
      ru: 'Apple iPhone 16 Pro 8/256GB Black Titanium',
      kk: 'Apple iPhone 16 Pro 8/256GB Black Titanium',
    },
    image: new URL('./images/dop-product.png', import.meta.url).href,
  },
  {
    place: {
      ru: '1 место',
      kk: '1 орын',
    },
    title: {
      ru: 'Apple iPhone 16 Pro 8/256GB Black Titanium',
      kk: 'Apple iPhone 16 Pro 8/256GB Black Titanium',
    },
    image: new URL('./images/product pic-1.png', import.meta.url).href,
  },
  {
    place: {
      ru: '2 место',
      kk: '2 орын',
    },
    title: {
      ru: 'Ноутбук Apple MacBook Air 13 Midnight 2024 M3',
      kk: 'Ноутбук Apple MacBook Air 13 Midnight 2024 M3',
    },
    image: new URL('./images/product pic-2.png', import.meta.url).href,
  },
  {
    place: {
      ru: '3 место',
      kk: '3 орын',
    },
    title: {
      ru: 'Игровая консоль Sony PlayStation 5 Slim (CFI-2008A)',
      kk: 'Sony PlayStation 5 Slim (CFI-2008A) ойын консолі',
    },
    image: new URL('./images/product pic-3.png', import.meta.url).href,
  },
  {
    place: {
      ru: '4-6 место',
      kk: '4-6 орын',
    },
    title: {
      ru: 'Стайлер Dyson Airwrap HS05 Long Diffuse синий/медь',
      kk: 'Стайлер Dyson Airwrap HS05 Long Diffuse көк/мыс',
    },
    image: new URL('./images/product pic-4.png', import.meta.url).href,
  },
  {
    place: {
      ru: '7,8 место',
      kk: '7,8 орын',
    },
    title: {
      ru: 'Фен Dyson HD07 (синий/медь)',
      kk: 'Dyson HD07 (көк/мыс) фені',
    },
    image: new URL('./images/product pic-5.png', import.meta.url).href,
  },
  {
    place: {
      ru: '9-11 место',
      kk: '9-11 орын',
    },
    title: {
      ru: 'Смарт Часы Apple Watch SE 40mm ',
      kk: 'Смарт Сағат Apple Watch SE 40mm ',
    },
    image: new URL('./images/product pic-6.png', import.meta.url).href,
  },
  {
    place: {
      ru: '12 место',
      kk: '12 орын',
    },
    title: {
      ru: 'Беспроводной пылесос HOFMANN',
      kk: 'HOFMANN сымсыз шаңсорғыш',
    },
    image: new URL('./images/product pic-7.png', import.meta.url).href,
  },
  {
    place: {
      ru: '13 – 15 место',
      kk: '13 – 15 орын',
    },
    title: {
      ru: 'Наушники Вставные AirPods 4',
      kk: 'AirPods 4 құлаққаптары',
    },
    image: new URL('./images/product pic-8.png', import.meta.url).href,
  },
  {
    place: {
      ru: '16, 17 место',
      kk: '16, 17 орын',
    },
    title: {
      ru: 'Электрогриль HOFMANN',
      kk: 'HOFMANN электрогрилі',
    },
    image: new URL('./images/product pic-9.png', import.meta.url).href,
  },
  {
    place: {
      ru: '18 – 20 место',
      kk: '18 – 20 орын',
    },
    title: {
      ru: 'Планетарный миксер HOFMANN',
      kk: 'HOFMANN планетарлық миксері',
    },
    image: new URL('./images/product pic-10.png', import.meta.url).href,
  },
  {
    place: {
      ru: '21 – 24 место',
      kk: '21 – 24 орын',
    },
    title: {
      ru: 'Микроволновая Печь HOFMANN',
      kk: 'HOFMANN микротолқынды пеші',
    },
    image: new URL('./images/product pic-11.png', import.meta.url).href,
  },
  {
    place: {
      ru: '25 – 29 место',
      kk: '25 – 29 орын',
    },
    title: {
      ru: 'Соковыжималка HOFMANN',
      kk: 'HOFMANN шырынсыққышы',
    },
    image: new URL('./images/product pic-12.png', import.meta.url).href,
  },
  {
    place: {
      ru: '30 – 34 место',
      kk: '30 – 34 орын',
    },
    title: {
      ru: 'Увлажнитель воздуха HOFMANN',
      kk: 'HOFMANN ауа ылғалдандырғыш',
    },
    image: new URL('./images/product pic-13.png', import.meta.url).href,
  },
  {
    place: {
      ru: '35 – 41 место',
      kk: '35 – 41 орын',
    },
    title: {
      ru: 'Ручной блендер HOFMANN',
      kk: 'HOFMANN қол блендері',
    },
    image: new URL('./images/product pic-14.png', import.meta.url).href,
  },
  {
    place: {
      ru: '42 – 50 место',
      kk: '42 – 50 орын',
    },
    title: {
      ru: 'Тостер HOFMANN',
      kk: 'HOFMANN тостері',
    },
    image: new URL('./images/product pic-15.png', import.meta.url).href,
  },
];
