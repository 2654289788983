<template>
  <div class="StoriesGroup" :data-code="group.code">
    <img
      v-if="isLoaded && !videoUrl"
      :key="imageUrl || videoUrl"
      class="StoriesGroup__img"
      :src="imageUrl"
      alt=""
    />
    <StoryVideo
      v-if="videoUrl"
      :key="imageUrl || videoUrl"
      :video-url="videoUrl"
      :poster="imageUrl"
      :paused="$attrs.paused"
      v-on="$listeners"
    />

    <!--
      iOS не смотря ни на что при удержании на картинке
      увеличивает её и показывает какое-то превью.
      Перекроем её невидимым слоем
    -->
    <div
      class="StoriesGroup__imgOverlay"
      v-on="{ touchstart, touchend }"
      @contextmenu.prevent="() => {}"
    />

    <TimeLine
      :count="slides.length"
      :current-idx="$attrs.currentSlideIdx"
      :progress="$attrs.progress"
    />

    <button
      v-if="!$isDesktop"
      class="StoriesGroup__btnClose"
      @click.stop.prevent="$emit('onClose')"
    >
      <IconCross />
    </button>

    <LoaderAnimation
      v-if="!isLoaded && !isFailed && !isVideo"
      class="StoriesGroup__loaderAnimation"
    />
    <LoaderFailed v-if="isFailed" class="StoriesGroup__loaderFailed" />

    <GoToButton
      v-if="compButtonText"
      class="StoriesGroup__btnGoTo"
      @click.prevent.stop="$emit('onCategoryButtonClick')"
    >
      {{ compButtonText }}
    </GoToButton>
  </div>
</template>

<script>
import TimeLine from './components/TimeLine/TimeLine.vue';
import GoToButton from './components/GoToButton/GoToButton.vue';
import LoaderAnimation from './components/LoaderAnimation/LoaderAnimation.vue';
import LoaderFailed from './components/LoaderFailed/LoaderFailed.vue';
import StoryVideo from './components/StoryVideo/StoryVideo.vue';
import IconCross from './icons/cross.svg';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

/**
 * Если держим пальцем дольше этого периода —
 * больше не считаем это тапом.
 * Нужно, конечно же, для айфона
 */
const TAP_MAX_DELAY = 150;

export default {
  name: 'StoriesGroup',
  components: {
    TimeLine,
    GoToButton,
    LoaderAnimation,
    LoaderFailed,
    StoryVideo,
    IconCross,
  },
  inheritAttrs: false,
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  computed: {
    slides() {
      return this.group.stories;
    },
    currentSlide() {
      return this.slides[this.$attrs.currentSlideIdx];
    },
    isLoaded() {
      return this.currentSlide.isLoaded;
    },
    isFailed() {
      return this.currentSlide.isFailed;
    },
    isVideo() {
      return this.currentSlide.isVideo;
    },
    imageUrl() {
      const prop = this.$i18n.locale === 'kk' ? 'imageKk' : 'imageRu';
      return this.currentSlide[prop];
    },
    videoUrl() {
      const prop = this.$i18n.locale === 'kk' ? 'videoKk' : 'videoRu';
      return this.currentSlide[prop];
    },
    isLastSlide() {
      return this.$attrs.currentSlideIdx === this.slides.length - 1;
    },
    compButtonText() {
      // buttonText && isLastSlide
      if (!this.isLastSlide && !this.$attrs.showButtonOnEachSlide) {
        return null;
      }

      if (this.$attrs.hideButtonIfNoUrl && !this.group.url) {
        return null;
      }

      const prop = this.$i18n.locale === 'kk' ? 'buttonTextKk' : 'buttonTextRu';
      if (this.group[prop]) {
        return this.group[prop];
      }

      return this.$attrs.buttonText || null;
    },
  },
  mounted() {
    const c = this.$attrs.currentSlideIdx;
    Analitycs.logEvent(EVENTS.STORIES_VIEWED, {
      index: c,
      code: this.$attrs.currentGroup.code,
    });
  },
  methods: {
    touchstart(event) {
      const el = event.currentTarget;
      el.startTime = Date.now();
      el.startX = event.clientX;
      el.startY = event.clientY;
    },
    touchend(event) {
      const el = event.currentTarget;
      const currentTime = Date.now();

      const x = event.changedTouches[0].clientX;
      const y = event.changedTouches[0].clientY;

      if (el.startX !== x || el.startY !== y) {
        return;
      }

      if (currentTime - el.startTime > TAP_MAX_DELAY) {
        return;
      }

      // тапнули слева
      if (x < window.innerWidth / 2) {
        this.goPrev();
      }

      // тапнули справа
      if (x > window.innerWidth / 2) {
        this.goNext();
      }
    },
    goPrev() {
      const c = this.$attrs.currentSlideIdx;
      if (c === 0) {
        this.$emit('onGoToPrevGroup');
      } else {
        this.$emit('onSetCurrentSlideIdx', c - 1);
      }
    },
    goNext() {
      const c = this.$attrs.currentSlideIdx;
      if (this.isLastSlide) {
        this.$emit('onGoToNextGroup');
      } else {
        this.$emit('onSetCurrentSlideIdx', c + 1);
      }
      Analitycs.logEvent(EVENTS.STORIES_VIEWED, {
        index: c + 1,
        code: this.$attrs.currentGroup.code,
      });
    },
  },
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.StoriesGroup {
  width: 100%;
  height: 100%;
  background: rgba(36, 36, 36, 1);
  /*
    Фикс, для кого? Для ios)
    Без этого при возврате свайпом к предыдущей истории
    у текущей дёргается кнопка
   */
  opacity: 0.99;
}

.StoriesGroup__btnClose {
  position: absolute;
  top: 36px;
  right: 12px;
}

.StoriesGroup__btnClose::before {
  content: '';
  display: block;
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
  margin-top: -50%;
  margin-left: -50%;
}

.StoriesGroup__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  pointer-events: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.StoriesGroup__imgOverlay {
  width: 100%;
  height: 100%;
}

.StoriesGroup__btnGoTo {
  position: absolute;
  bottom: 36px;
  left: 16px;
  right: 16px;
}

.StoriesGroup__loaderAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.StoriesGroup__loaderFailed {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

@media screen and (min-width: 768px) {
  .StoriesGroup {
    border-radius: 32px;
    overflow: hidden;
  }

  .StoriesGroup__btnGoTo {
    bottom: 40px;
    left: 32px;
    right: 32px;
  }
}
</style>
