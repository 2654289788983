<template>
  <div class="MapView">
    <div id="map" class="MapView__map"></div>
    <MapControls
      :zoom="zoomBtns"
      :geo="geoBtn"
      :geo-loading="geoLoading"
      @onZoomIn="zoom('in')"
      @onZoomOut="zoom('out')"
      @onGeo="defineGeoLocation"
    />
    <MarkerPin
      class="MapView__pin"
      :disabled="pinState === 'disabled'"
      :distant="pinState === 'extended'"
      :loading="pinState === 'loading'"
      :pinup="isMapTouching"
    >
      <slot name="deliveryTime">
        ~ {{ pinState === 'extended' ? 30 : 15 }} минут
      </slot>
    </MarkerPin>
  </div>
</template>

<script>
import YandexMap from './map/yandex-map';
import MarkerPin from '@pure-ui/components/Map/MarkerPin/MarkerPin.vue';
import MapControls from '@pure-ui/components/Map/MapControls/MapControls.vue';

export default {
  components: { MarkerPin, MapControls },
  props: {
    initCoords: {
      type: Array,
      default: () => [43.237242, 76.915075],
    },
    polygons: {
      type: Object,
      default: () => {
        return {
          regular: [],
          extended: [],
        };
      },
    },
    inputCoords: {
      type: Array,
      default: () => [],
    },
    geoBtn: Boolean,
    zoomBtns: Boolean,
  },
  data() {
    return {
      mapInstance: null,

      isMapTouching: false,

      geoLoading: false,

      pinState: 'loading',
    };
  },
  watch: {
    polygons: {
      handler() {
        if (this.mapInstance) this.setPolygons();
      },
      immediate: true,
    },
    inputCoords: {
      handler(coords) {
        if (coords && Array.isArray(coords) && coords.length === 2) {
          this.defineByCoords(coords);
        }
      },
      deep: true,
    },
  },
  created() {
    this.mapInstance = new YandexMap(
      this.onMapInit,
      this.onMapClick,
      this.onMapActionEnd,
      this.onMapActionStart,
      this.onMapCenterUpdated
    );
  },
  mounted() {
    this.mapInstance.initMap('map', this.initCoords);
  },
  methods: {
    setPolygons() {
      this.mapInstance.setPolygons(this.polygons);
      this.$emit('onDeliveryPolygonsSetted');
    },
    onMapCenterUpdated(data) {
      let {
        isInTerritory,
        isInExtended,
        hasStreet,
        hasBuilding,
        isPolygonsLoaded,
      } = data;

      if (isPolygonsLoaded) this.$emit('onMapCenterUpdated', data);
      let isValidAddress = hasStreet && hasBuilding;
      let isInDeliveryZone = isInTerritory || isInExtended;
      if (!isPolygonsLoaded) this.pinState = 'loading';
      else if (!isInDeliveryZone || !isValidAddress) this.pinState = 'disabled';
      else if (isInDeliveryZone && isInExtended) this.pinState = 'extended';
      else this.pinState = 'regular';
    },
    onMapClick(options) {
      this.$emit('onMapClick', options);
    },
    onMapActionStart(options) {
      this.$emit('onMapActionStart', options);
      this.isMapTouching = true;
      this.pinState = 'loading';
    },
    onMapActionEnd(options) {
      this.$emit('onMapActionEnd', options);
      this.isMapTouching = false;
    },
    onMapInit(options) {
      this.$emit('onMapInit', { map: this.mapInstance, ...options });
      this.setPolygons();
    },
    zoom(zoom = 'in') {
      this.mapInstance.zoom(zoom);
    },
    defineByCoords(coords) {
      return this.mapInstance.defineByCoords(coords);
    },
    defineGeoLocation() {
      this.geoLoading = true;

      return this.mapInstance
        .findGeoLocation(5000)
        .catch(() => this.$emit('onGeoLocationError'))
        .finally(() => {
          this.geoLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.MapView {
  @apply relative;
  width: 100%;
  height: 100%;
}
.MapView__map {
  width: 100%;
  height: 100%;
}
.MapView__pin {
  @apply absolute z-10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}
</style>
