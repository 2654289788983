<template>
  <div class="DescriptionText">
    <p>
      <template v-if="$i18n.locale === 'ru'">
        Удаление аккаунта в приложении Даркстор и других проектов ChocoFamily
      </template>
      <template v-if="$i18n.locale === 'kk'">
        Даркстор қосымшасы мен ChocoFamily-дің өзге жобаларындағы аккаунтты жою
      </template>
    </p>
    <ul>
      <template v-if="$i18n.locale === 'ru'">
        <li class="DescriptionText__li">
          — После удаления аккаунта вы потеряете доступ к аккаунту в приложении
          Даркстор и также потеряете доступ к другим проектам ChocoFamily
        </li>
        <li class="DescriptionText__li">
          — У вас будет 90 дней для восстановления аккаунта. Для восстановления
          нужно будет авторизоваться в одном из проектов ChocoFamily.
        </li>
        <li class="DescriptionText__li">
          — По истечению 90 дней ваши данные будут удалены безвозвратно
        </li>
      </template>
      <template v-if="$i18n.locale === 'kk'">
        <li class="DescriptionText__li">
          — Аккаунтты жойғаннан кейін сіз Даркстор қосымшасындағы аккаунтқа кіре
          алмайсыз және ChocoFamily-дің өзге жобаларына қолжетімділіктен
          айырыласыз
        </li>
        <li class="DescriptionText__li">
          — Сізге аккаунтты қалпына келтіруге 90 күн беріледі. Қалпына келтіру
          үшін ChocoFamily жобаларының біріне авторизациялану қажет.
        </li>
        <li class="DescriptionText__li">
          — 90 күн өткеннен кейін деректеріңіз қайтарымсыз жойылады
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DescriptionText',
  components: {},
  inject: [],
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.DescriptionText {
  font-size: 14px;
  color: #7b7a8c;
}

.DescriptionText__li {
  padding-top: 8px;
}
</style>
