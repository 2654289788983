<template>
  <div class="ContestBanners">
    <div
      v-for="(banner, index) in banners"
      :key="index"
      class="ContestBanners__item"
      :style="`background: ${banner.background}; color: ${banner.color};`"
    >
      <p class="ContestBanners__title" v-html="$t(banner.text)"></p>
      <div
        class="ContestBanners__img"
        :style="`background-image: url(${banner.img})`"
      ></div>
    </div>
  </div>
</template>

<script setup>
const banners = [
  {
    text: 'banner1',
    img: new URL('./imgs/banner1.png', import.meta.url).href,
    color: '#242424',
    background: 'white',
  },
  {
    text: 'banner2',
    img: new URL('./imgs/banner2.png', import.meta.url).href,
  },
  {
    text: 'banner3',
    img: new URL('./imgs/banner3.png', import.meta.url).href,
  },
];
</script>

<script>
export default {
  i18n: {
    messages: {
      ru: {
        banner1:
          'Оформляй заказы на сумму от 3000&nbsp;₸ на сайте или&nbsp;в&nbsp;приложении',
        banner2:
          'Набери более 50 баллов совершая покупки до&nbsp;14&nbsp;января 2025',
        banner3: 'Участвуй </br> в&nbsp;розыгрыше </br> призов',
      },
      kk: {
        banner1:
          'Сайтта немесе&nbsp;қосымшада 3000&nbsp;₸ асатын сомаға тапсырыстар беріңіз',
        banner2:
          '2025 жылғы&nbsp;14&nbsp;қаңтарға дейін сауда жасап, 50 ұпайдан артық жинаңыз',
        banner3: 'Сыйлықтар ұтысына қатысыңыз',
      },
    },
  },
};
</script>

<style scoped>
.ContestBanners {
  @apply w-full flex flex-col gap-y-4
  md:flex-row md:gap-y-0 md:gap-x-3;
}
.ContestBanners__item {
  @apply p-6 relative flex w-full overflow-hidden 
  md:p-10;
  height: 260px;
  border-radius: 32px;
  background: #00774c;
  color: white;
}
.ContestBanners__title {
  @apply pt-0 text-xl font-bold leading-6 
  md:text-2xl md:leading-30;
}
.ContestBanners__img {
  @apply h-full w-full absolute right-0 top-0;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  max-width: 280px;
}

@media (min-width: 768px) {
  .ContestBanners__item {
    height: 490px;
    border-radius: 64px;
  }
  .ContestBanners__img {
    max-width: unset;
    background-position: center bottom;
  }
}
</style>
