<template>
  <video
    v-if="videoUrl"
    ref="video"
    :src="videoUrl"
    :poster="poster"
    autoplay
    muted
    playsinline
    class="StoryVideo"
    @playing="isPlaying = true"
  />
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';

const props = defineProps({
  videoUrl: {
    type: String,
    default: '',
  },
  poster: {
    type: String,
    default: '',
  },
  paused: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onVideoProgressChange']);
const video = ref(null);
const isPlaying = ref(false);

watch(
  () => props.paused,
  v => {
    isPlaying.value = !v;
    video.value[v ? 'pause' : 'play']?.();
  }
);

watch(isPlaying, () => {
  (function startEmittingProgress() {
    if (!isPlaying.value || !video.value) return;
    const { duration, currentTime } = video.value;
    const progress = (currentTime / duration) * 100;
    emit('onVideoProgressChange', progress);
    if (progress === 100) return;
    requestAnimationFrame(startEmittingProgress);
  })();
});

function onReconnectToApp() {
  $log('StoryVideo.vue onReconnectToApp');

  setTimeout(() => {
    video.value.play();
  }, 300);
}

onMounted(() => {
  eventBus.on('eb_on_reconnect_to_app', onReconnectToApp);
});

onUnmounted(() => {
  eventBus.off('eb_on_reconnect_to_app', onReconnectToApp);
});
</script>

<style scoped>
.StoryVideo {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  height: calc(100% + 2px);
  max-width: none;
  width: calc(100% + 2px);
  object-fit: cover;
  object-position: top center;
  pointer-events: none;
}
</style>
