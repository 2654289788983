<template>
  <h2
    class="NyPageSubtitle"
    :class="`
      ${color}
    `"
  >
    <slot />
  </h2>
</template>

<script>
const COLORS = {
  green: 'green',
  black: 'black',
};

export default {
  name: 'NyPageSubtitle',
  props: {
    color: {
      type: String,
      default: 'green',
      validator: v => COLORS.hasOwnProperty(v),
    },
  },
};
</script>

<style scoped>
.NyPageSubtitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #135141;
}

.NyPageSubtitle.black {
  @apply text-sirius-black-100;
}

@media screen and (min-width: 768px) {
  .NyPageSubtitle {
    font-size: 36px;
    line-height: 44px;
  }
}
</style>
