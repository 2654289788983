<template>
  <div class="TopHero">
    <TopHeroMobile v-if="!$isDesktop" />
    <TopHeroDesktop v-else />
  </div>
</template>

<script>
import TopHeroMobile from '@/views/ny-2024-contest/components/TopHero/components/TopHeroMobile/TopHeroMobile.vue';
import TopHeroDesktop from '@/views/ny-2024-contest/components/TopHero/components/TopHeroDesktop/TopHeroDesktop.vue';

export default {
  name: 'TopHero',
  components: {
    TopHeroMobile,
    TopHeroDesktop,
  },
};
</script>

<style scoped></style>
