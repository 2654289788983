import { client } from '@shared/services/api/clients/client';

function getSuggestions(payload) {
  return client
    .get('lavka-favorites/v1/cross-sell/suggestions', {
      params: payload,
    })
    .then(response => response.data);
}

export const CrossSellService = {
  getSuggestions,
};
