<template>
  <div class="TopHeroMobile">
    <div class="TopHeroMobile__bg" />
    <div class="TopHeroMobile__container">
      <WhiteBreadcrumbs v-if="!$isWebView" class="TopHeroMobile__breadcrumbs" />
      <h1 class="TopHeroMobile__title" v-html="$t('xmasFatherNear')" />
      <div class="TopHeroMobile__descr" v-html="$t('descr')" />
      <ReviewsList class="TopHeroMobile__reviews" />
    </div>
  </div>
</template>

<script>
import WhiteBreadcrumbs from '../WhiteBreadcrumbs/WhiteBreadcrumbs.vue';
import ReviewsList from '../ReviewsList/ReviewsList.vue';

export default {
  name: 'TopHeroMobile',
  components: {
    ReviewsList,
    WhiteBreadcrumbs,
  },
  i18n: {
    messages: {
      ru: {
        xmasFatherNear: 'Дед&nbsp;Мороз Рядом',
        descr:
          'Cовершайте покупки от&nbsp;3000&nbsp;₸ и&nbsp;выигрывайте призы',
      },
      kk: {
        xmasFatherNear: 'Рядом&nbsp;Аяз Атасы',
        descr: '3000 теңгеден бастап тапсырыс жасап, сыйлықтар ұтып ал',
      },
    },
  },
};
</script>

<style scoped>
.TopHeroMobile {
  position: relative;
  overflow: hidden;
  color: #fff;
  z-index: 0;
  min-height: 500px;
}

.TopHeroMobile__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 600px;
  margin: auto;
  height: 380px;
  border-radius: 0 0 72px 72px;
  background: url('./images/back_mobile.png') no-repeat center top / 100%;
}

.TopHeroMobile__container {
  position: relative;
  width: calc(100% - 32px);
  margin: auto;
  padding-top: 98px;
}

.TopHeroMobile__breadcrumbs {
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 26px;
  width: fit-content;
}

.TopHeroMobile__title {
  max-width: 315px;
  margin: auto;

  color: var(--text-text-invert, #fff);
  text-align: center;
  text-shadow: 0px 4px 24px rgba(237, 86, 108, 0.48);

  /* web/h2 web */
  font-size: 52px;
  font-weight: 900;
  line-height: 46px;
  letter-spacing: -3px;
}

.TopHeroMobile__descr {
  padding-top: 12px;
  margin: auto;
  max-width: 240px;

  color: var(--text-text-invert, #fff);
  text-align: center;
  text-shadow: 0px 2px 16px rgba(255, 54, 85, 0.24);

  /* mobile/body/large, decorative/medium 18 */
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.TopHeroMobile__reviews {
  position: relative;
  max-width: 323px;
  margin: auto;
  margin-top: 46px;
}
</style>
