<template>
  <div
    class="CategoryBlock"
    @mouseover="onMouseOver"
    @mouseleave="isHovered = false"
    @click="onNavigate"
  >
    <template v-if="withImage">
      <img
        v-if="imgSrc === 'NoPhoto'"
        class="CategoryBlock__img"
        :src="NoPhoto"
        width="40"
        height="40"
        alt="no-photo"
      />
      <img
        v-else
        v-img-proxy="{ url: imgSrc, size: 's:40:40' }"
        class="CategoryBlock__img"
        width="40"
        height="40"
        alt="category"
      />
    </template>
    <p
      class="ml-2 transition-all duration-100"
      :class="{
        'text-sirius-orange-100': isHovered || isActive,
        'font-medium': type !== 'child',
      }"
    >
      {{ category.name }}
    </p>

    <IconRightArrowLine
      v-if="expandable"
      class="ml-auto transition-all duration-100"
      :class="arrowClasses"
    />
  </div>
</template>

<script>
import IconRightArrowLine from '@shared/components/icons/IconRightArrowLine.vue';
import NoPhoto from '@img/no-photo.png';

export default {
  name: 'CategoryBlock',
  components: { IconRightArrowLine },
  props: {
    category: {
      type: Object,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    withImage: {
      type: Boolean,
      default: true,
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'catalog',
    },
    isGroupIcon: {
      type: Boolean,
      default: false,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovered: false,
      NoPhoto,
    };
  },
  computed: {
    arrowClasses() {
      if (this.type === 'category') {
        if (this.isHovered) return 'text-sirius-orange-100';
        return this.isActive ? 'text-transparent' : 'text-sirius-gray-100';
      }
      if (this.type === 'catalog') {
        return this.expandable && (this.isActive || this.isHovered)
          ? 'text-sirius-orange-100'
          : 'text-transparent';
      }
      if (this.type === 'search') {
        return this.isHovered
          ? 'text-sirius-orange-100'
          : 'text-sirius-gray-100';
      }
      return '';
    },
    imgSrc() {
      if (this.isChild) {
        return this.category.icon_small ?? 'NoPhoto';
      }
      return this.category.icon ?? 'NoPhoto';
    },
  },
  methods: {
    onMouseOver() {
      if (this.type === 'catalog') {
        if (this.category.categories?.length > 0) {
          this.isHovered = true;
          if (this.expandable) this.$emit('onHover', this.category);
        }
      } else {
        this.isHovered = true;
      }
    },
    onNavigate() {
      if (this.isActive && !this.isCategory) return;
      this.$emit('onNavigate');
    },
  },
};
</script>

<style scoped>
.CategoryBlock {
  @apply w-full flex items-center cursor-pointer;
}
.CategoryBlock__img {
  @apply w-full h-full rounded-lg overflow-hidden object-contain object-left-bottom;
  background: #f7f3f1;
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
}
</style>
