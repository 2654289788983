import { RahmetApp } from '@shared/RahmetApp';
import { createStorage } from '@shared/services/storage';
import { parseToken } from '@shared/utils/parse-token';
import { v4 as uuidv4 } from 'uuid';

const storage = createStorage(window.localStorage);

function getInitRefresh() {
  if (RahmetApp.isWebView()) return undefined;
  return storage.get('auth::refresh-token') || undefined;
}

const clientContext = {
  deviceId: undefined,
  sessionId: undefined,
  platform: undefined,
  fingerprint: '',
  v: undefined,

  accessToken: import.meta.env.DEV ? storage.get('auth::token') : undefined,
  refreshToken: getInitRefresh(),
  expireIn: import.meta.env.DEV ? storage.get('auth::expire-in') : undefined,
};

const updateContext = newContext => {
  Object.assign(clientContext, newContext);
};
const getContext = () => {
  return clientContext;
};

function getFingerprint() {
  let { fingerprint } = getContext();
  if (fingerprint) fingerprint;
  fingerprint = `${uuidv4()}`;
  updateContext({ fingerprint });
  return fingerprint;
}

const getUserId = () => {
  let userId = -1;
  let jwt = null;
  const { accessToken } = getContext();
  if (accessToken) jwt = parseToken(accessToken);
  if (jwt?.sub) userId = jwt.sub;
  return userId;
};

function generateDeviceId() {
  const prefix = RahmetApp.isWebView()
    ? 'miniapp'
    : window.innerWidth > 768
      ? 'desktop'
      : 'adaptive';

  return `${prefix}-${uuidv4()}`;
}

function getDeviceId() {
  return storage.getItem('deviceIdUuid', generateDeviceId());
}

function setDeviceId(deviceId) {
  if (!deviceId) deviceId = getDeviceId();
  storage.setItem('deviceIdUuid', deviceId);
  updateContext({ deviceId });
}

export const ClientService = {
  getFingerprint,
  updateContext,
  getContext,
  getUserId,
  getDeviceId,
  setDeviceId,
};
