<template>
  <div class="SpecialCategorySlider">
    <div class="SpecialCategorySlider__wrapper">
      <div class="SpecialCategorySlider__content">
        <img src="../SpecialCategorySlider/assets/pic.png" />
        <div class="label">
          <p class="text-sm font-medium">{{ $t('glowup') }}</p>
        </div>
        <div class="SpecialCategorySlider__info">
          <p class="font-medium text-xs" v-html="$t('title')"></p>
          <UiButton
            variant="white"
            size="SM"
            class="font-bold text-base"
            style="
              background-color: white;
              width: 131px;
              height: 36px;
              margin-top: 16px;
            "
            @click="goToCategory('details')"
          >
            {{ $t('seeMore') }}
            <svg
              style="margin-left: 10px"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9L5 5L1 1"
                stroke="#242424"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </UiButton>
        </div>
      </div>
      <div class="SpecialCategorySlider__slider">
        <Slider :items="products" :desktop-max-per-screen="1">
          <div class="SpecialCategorySlider__container">
            <div v-for="slide in products" :key="slide.id">
              <ProductCardWrapper
                :product="slide"
                is-horizontal
                style="margin-left: 8px"
                :added-from="PRODUCT_ADDED.PROMO_BANNER_ON_MAIN"
              />
            </div>
            <div
              v-if="products.length >= 2"
              class="arrow"
              @click="goToCategory('arrow')"
            >
              <SwiperArrow />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import Slider from '@pure-ui/components/CommercialBanners/components/Slider.vue';
import SwiperArrow from '@pure-ui/components/UiSwiper/components/SwiperArrow.vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { PRODUCT_ADDED } from '@shared/config/product';
import { DevConfigs } from '@/utils/dev-config';

export default {
  name: 'SpecialCategorySlider',
  components: { SwiperArrow, Slider, ProductCardWrapper, UiButton },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    PRODUCT_ADDED() {
      return PRODUCT_ADDED;
    },
  },
  methods: {
    sendEvent(from) {
      Analitycs.logEvent(EVENTS.PROMO_BANNER_DETAILS_CLICK, {
        code: '',
        category_id: 1478,
        from: from,
      });
    },
    goToCategory(from) {
      this.sendEvent(from);
      const categoryId = DevConfigs.isProd ? 1478 : 1439;
      this.$router.push(`/category/${categoryId}`);
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Идеальные букеты для&nbsp;вашего повода',
        glowup: 'расцветаем',
        seeMore: 'Подробнее',
      },
      kk: {
        title: 'Сіздің себетке арналған мінсіз букеттер',
        glowup: 'гүлденеміз',
        seeMore: 'Толығырақ',
      },
    },
  },
};
</script>

<style scoped>
.SpecialCategorySlider {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.SpecialCategorySlider__wrapper {
  width: calc(100vw - 32px);
  height: 320px;
  display: flex;
  flex-direction: column;
  background-image: url('assets/bar.svg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.SpecialCategorySlider__wrapper img {
  height: 312px;
  max-width: none;
  width: 220px;
  margin-left: -7%;
  margin-top: -8%;
}

.SpecialCategorySlider__content {
  display: flex;
  flex-direction: row;
}

.SpecialCategorySlider__info {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 60%;
  margin-left: -30px;
}

.SpecialCategorySlider__info p {
  font-size: 16px;
  line-height: 20px;
}

.SpecialCategorySlider__container {
  border-left: 16px solid transparent;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: var(--between);
}

.SpecialCategorySlider__slider {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.SpecialCategorySlider__container::after {
  content: '';
  display: block;
  min-width: 8px;
}

.arrow {
  width: 70px;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

:deep(.SwiperArrow svg) {
  width: 20px;
  height: 20px;
}

.SwiperArrow {
  width: 40px;
  height: 40px;
}

.label {
  position: absolute;
  background: #d361a5;
  padding: 4px 12px;
  width: 106px;
  height: 24px;
  color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotate(-14deg);
  left: 18px;
}
</style>
