<template>
  <div class="WinnersBlock">
    <h3 style="color: #135141">{{ $t('winners') }}</h3>
    <h4 style="color: #00774c">{{ $t('valuablePrizes') }}</h4>
    <p v-html="$t('valuablePrizesDescription')"></p>
    <h4 class="md:pt-6" style="color: #00774c" v-html="$t('iphoneRaffle')"></h4>
    <p v-html="$t('iphoneRaffleDescription')"></p>
  </div>
</template>

<script>
export default {
  i18n: {
    messages: {
      ru: {
        winners: 'Победители',
        valuablePrizes: '50 ценных призов',
        valuablePrizesDescription: `50 ценных призов достанутся первым 50&nbsp;участникам, набравшим наибольшее количество баллов.`,
        iphoneRaffle: 'дополнительный розыгрыш iPhone&nbsp;16&nbsp;Pro',
        iphoneRaffleDescription: `Розыгрыш Apple&nbsp;iPhone&nbsp;16&nbsp;Pro&nbsp;128/8GB состоится среди
          участников, не&nbsp;вошедших в&nbsp;список ТОП&nbsp;–&nbsp;50,
          15&nbsp;января в&nbsp;прямом эфире в&nbsp;социальной сети Instagram
          (@ryadom.kz).`,
      },
      kk: {
        winners: 'Жеңімпаздар',
        valuablePrizes: '50 бағалы сыйлық',
        valuablePrizesDescription:
          '50 бағалы сыйлық бәрінен де көп ұпай жинаған алғашқы 50 қатысушыға беріледі.',
        iphoneRaffle: 'Қосымша ұтыс iPhone 16 Pro',
        iphoneRaffleDescription:
          'Apple iPhone 16 Pro 128/8GB ТОП – 50 тізіміне енбеген қатысушылар арасында 15 қаңтар күні Instagram желісінде (@ryadom.kz) тікелей эфирде ойнатылады.',
      },
    },
  },
};
</script>

<style scoped>
.WinnersBlock {
}

.WinnersBlock h4 {
  color: #acaaba;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding-top: 16px;
}

.WinnersBlock h4 + p {
  padding-top: 8px;
  margin-top: 0;
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .WinnersBlock h4 {
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
