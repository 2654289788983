<template>
  <div class="ReminderText">
    <div class="ReminderText__part1" v-html="texts.part1"></div>
    <br />
    <div class="ReminderText__part2" v-html="texts.part2"></div>
  </div>
</template>

<script>
export default {
  name: 'ReminderText',
  props: {
    /**
     * Количество баллов, которые юзер получит за сумму в заказе
     */
    score: {
      type: Number,
      default: 0,
    },
    /**
     * Количество уже набранных юзером баллов
     */
    userScore: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    texts() {
      let part1 = 'zero';
      if (this.score >= 1 && this.score < 5) part1 = 'moreOne';
      else if (this.score >= 5) part1 = 'moreFive';

      let part2 = 'less50';
      if (this.userScore >= 50) part2 = 'more50';
      return {
        part1: this.$t(part1, {
          score: this.$tc('scoreCount', this.score),
          userScore: this.$tc('scoreCount', this.userScore),
        }),
        part2: this.$t(part2),
      };
    },
  },
  i18n: {
    messages: {
      ru: {
        zero: `Сделайте заказ от&nbsp;3000&nbsp;₸ и&nbsp;получите баллы
          для&nbsp;участия в&nbsp;розыгрыше ценных призов&nbsp;✨
        `,
        moreOne: `У вас {userScore}. Вы получите {score} за&nbsp;этот заказ. 
        Получите 5&nbsp;баллов при заказе от&nbsp;5000&nbsp;₸&nbsp;✨
        `,
        moreFive: `У вас {userScore}. Вы получите еще {score} за&nbsp;этот заказ&nbsp;✨`,

        less50: `Чтобы участвовать в&nbsp;розыгрыше, накопите 50&nbsp;баллов.`,
        more50: `Вы участвуете в&nbsp;розыгрыше ценных призов&nbsp;🎉`,

        scoreCount:
          '0&nbsp;баллов | {n}&nbsp;балл | {n}&nbsp;балла | {n}&nbsp;баллов',
      },
      kk: {
        zero: `3000 ₸ жоғары сомаға тапсырыс жасап, бағалы сыйлықтар ұтыс ойнына
          арналған ұпайларға ие болыңыз&nbsp;✨`,
        moreOne: `Сізде {userScore}. Осы тапсырыс үшін {score}
          иеленесіз. 5000&nbsp;₸ жоғары тапсырыстар кезінде 5&nbsp;ұпай аласыз&nbsp;✨
        `,
        moreFive: `Сізде {userScore}. Осы тапсырыс үшін тағы {score}
          аласыз&nbsp;✨`,

        less50: `Ұтыс ойнына қатысу үшін 50&nbsp;ұпай жинау керек.`,
        more50: `Сіз бағалы сыйлықтар ұтыс ойнына қатысасыз&nbsp;🎉`,

        scoreCount: '{n}&nbsp;ұпай',
      },
    },
  },
};
</script>

<style scoped>
.ReminderText {
  font-size: 14px;
  line-height: 18px;
  color: #767485;
}
</style>
