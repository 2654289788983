<template>
  <div class="SuggestionsSlider mt-6 md:mt-16 mb-1 md:mb-6 mx-auto">
    <HorizontalProductList
      v-if="readySuggestions.length"
      :navigation="{ name: 'suggestions' }"
      :products="readySuggestions"
      :added-from="PRODUCT_ADDED.SUGGESTION_SLIDER_CART_PAGE"
      @onCardsViewed="onCardsViewed"
    >
      <template #title>
        {{ $t('recommend') }}
      </template>
    </HorizontalProductList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PRODUCT_ADDED } from '@shared/config/product';
import HorizontalProductList from '@shared/components/horizontal-products/HorizontalProductList.vue';
import { onCartLeave } from '@shared/components/shopping-history/on-cart-leave';
import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';

export default {
  name: 'SuggestionsSlider',
  components: {
    HorizontalProductList,
  },
  data: () => ({
    PRODUCT_ADDED,
    analytics: {
      cardsViewed: 0,
    },
  }),
  computed: {
    ...mapGetters('cart', ['items']),
    ...mapGetters('crossSell', ['suggestions']),
    readySuggestions() {
      return this.suggestions.slice(0, 9);
    },
  },
  mounted() {
    Analitycs.logEvent(EVENTS.VIEWED_SUGGESTIONS);
    this.loadSuggestions();
  },
  methods: {
    loadSuggestions() {
      const ids = this.items.map(item => item.product_id).join(',');
      this.$store.dispatch('crossSell/FETCH_SUGGESTIONS', { product_ids: ids });
      onCartLeave(this.logViewedCards);
    },
    logViewedCards() {
      const { cardsViewed } = this.analytics;
      if (cardsViewed) {
        this.logViewed(cardsViewed);
      }
    },
    onCardsViewed(event) {
      this.analytics.cardsViewed = event;
    },

    logViewed(amount) {
      Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
        screenName: 'Suggestion Slider Cart Page',
        pageNum: 1,
        amount,
      });
    },
  },
  eventBusEvents: {
    eb_on_locale_change() {
      this.loadSuggestions();
    },
    eb_on_address_change() {
      this.loadSuggestions();
    },
    eb_on_address_update() {
      this.loadSuggestions();
    },
  },
  i18n: {
    messages: {
      ru: {
        recommend: 'Добавить к заказу?',
      },
      kk: {
        recommend: 'Тапсырысқа қосайық па?',
      },
    },
  },
};
</script>
