<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 17.5C6.3575 17.5 3 14.1425 3 10C3 5.8575 6.3575 2.5 10.5 2.5C14.6425 2.5 18 5.8575 18 10C18 14.1425 14.6425 17.5 10.5 17.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7083 14.1667V10H9.875"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.499 6.66675C10.384 6.66675 10.2907 6.76008 10.2915 6.87508C10.2915 6.99008 10.3848 7.08341 10.4998 7.08341C10.6148 7.08341 10.7082 6.99008 10.7082 6.87508C10.7082 6.76008 10.6148 6.66675 10.499 6.66675Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="10.5033" cy="6.87825" r="0.833333" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconInfo',
};
</script>
