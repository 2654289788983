<template>
  <StoriesContainer
    v-if="visible"
    ref="storiesContainer"
    v-bind="{
      stories: localStories,
      currentSlideIdx,
      currentGroupIdx,
      progress,
      currentGroup,
      prevGroup,
      nextGroup,
      hideButtonIfNoUrl,
      buttonText,
      showButtonOnEachSlide,
      paused,
    }"
    v-on="{
      onBackgroundClick,
      onSetCurrentSlideIdx,
      onSetCurrentGroupIdx,
      onClose,
      onDesktopArrowLeftClick,
      onDesktopArrowRightClick,
      onCategoryButtonClick,
      onVideoProgressChange,
    }"
    @touchstart.native="touchstart"
    @touchend.native="touchend"
  />
</template>

<script>
import StoriesContainer from './components/StoriesContainer/StoriesContainer.vue';
import cloneDeep from 'lodash.clonedeep';
import { Timer } from './classes/Timer';
import { ImagesLoader } from './classes/ImagesLoader';

export default {
  name: 'InstaStories',
  components: {
    StoriesContainer,
  },
  props: {
    stories: {
      type: Array,
      default: () => [],
    },
    timeoutMs: {
      type: Number,
      default: 7000,
      // default: 1000,
    },
    buttonText: {
      type: String,
      default: '',
    },
    hideButtonIfNoUrl: {
      type: Boolean,
      default: false,
    },
    showButtonOnEachSlide: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visible: false,
    /**
     * В % по времени просмотрено текущего слайда
     */
    progress: 0,
    /**
     * индекс текущего слайда текущей сториз
     */
    currentSlideIdx: 0,
    /**
     * индекс текущего сторис, т. е. несколько слайдов на одну тему
     */
    currentGroupIdx: 0,
    /**
     * Когда история просмотрена, она перемещается в конец
     * Пока блок сторис не свернули, смотрим предыдущую версию
     */
    localStories: [],
    timer: null,
    imagesLoader: null,
    paused: false,
  }),
  computed: {
    currentGroup() {
      return this.localStories[this.currentGroupIdx];
    },
    currentSlide() {
      if (!this.currentGroup) return;
      return this.currentGroup.stories[this.currentSlideIdx];
    },
    prevGroup() {
      if (this.currentGroupIdx === 0) {
        return null;
      }
      return this.localStories[this.currentGroupIdx - 1];
    },
    nextGroup() {
      const lastIdx = this.localStories.length - 2;
      if (this.currentGroupIdx > lastIdx) {
        return null;
      }
      return this.localStories[this.currentGroupIdx + 1];
    },
    isLastSlide() {
      return this.currentSlideIdx === this.currentGroup.stories.length - 1;
    },
  },
  watch: {
    visible(v) {
      if (!v) {
        this.timer.reset();
        this.currentSlideIdx = 0;
      }
    },
    'currentSlide.isLoaded'(v) {
      if (v) {
        this.timer.start();
      }
    },
    paused(v) {
      if (v) this.timer.pause();
      else this.timer.resume();
    },
  },
  created() {
    this.timer = new Timer({
      timeoutMs: this.timeoutMs,
      onProgressUpdate: this.onProgressUpdate,
    });
    this.imagesLoader = new ImagesLoader({
      vm: this,
    });
  },
  methods: {
    /**
     * @param code id сториз
     * @param fromDeeplink если передан, значит после завершения показа переданного слайда блок сториз должен закрываться и не переходить к следующим
     */
    show(code, fromDeeplink = false) {
      this.localStories = cloneDeep(this.stories);
      if (fromDeeplink) {
        const story = this.stories.find(v => v.code === code);
        this.localStories = [cloneDeep(story)];
      }
      this.currentGroupIdx = this.localStories.findIndex(v => v.code === code);
      this.visible = true;
      this.preloadGroupImages();
      if (this.currentSlide.isLoaded) {
        this.timer.start();
      }
    },
    onBackgroundClick() {
      this.close('background');
    },
    onSetCurrentSlideIdx(idx) {
      this.currentSlideIdx = idx;
      if (this.currentSlide.isLoaded) {
        this.timer.start();
      } else {
        this.timer.reset();
      }
    },
    onSetCurrentGroupIdx(idx) {
      this.$emit('onStoryEnd', this.currentGroup);
      this.currentGroupIdx = idx;
      this.currentSlideIdx = 0;
      if (this.currentSlide.isLoaded) {
        this.timer.start();
      } else {
        this.timer.reset();
      }
      this.preloadGroupImages();
    },
    onClose(method) {
      this.close(method);
    },
    close(method = 'button') {
      if (method === 'end') {
        this.$emit('onStoryEnd', this.currentGroup);
      }
      this.$emit('onClose', {
        method,
        index: this.currentSlideIdx,
        code: this.currentGroup.code,
        banner: this.currentGroup,
      });
      this.visible = false;
    },
    touchstart() {
      this.paused = true;
    },
    touchend() {
      this.paused = false;
    },
    onDesktopArrowLeftClick() {
      this.goPrev();
    },
    onDesktopArrowRightClick() {
      this.goNext();
    },
    goNext() {
      // todo: по идее костыль. Правильнее в самом компоненте
      // отслеживать изменение пропа
      this.$refs.storiesContainer?.$refs.storiesCube.$refs.storyGroup.goNext();
    },
    goPrev() {
      this.$refs.storiesContainer?.$refs.storiesCube.$refs.storyGroup.goPrev();
    },
    onProgressUpdate(v) {
      this.progress = v;
      if (v === 100 && this.visible) {
        setTimeout(() => {
          this.goNext();
        });
      }
    },
    preloadGroupImages() {
      const queue = this.imagesLoader.preloadGroup({
        idx: this.currentGroupIdx,
      });
      if (this.nextGroup) {
        queue.then(() => {
          this.imagesLoader.preloadGroup({ idx: this.currentGroupIdx + 1 });
        });
      }
    },
    onCategoryButtonClick() {
      this.$emit('onCategoryButtonClick', {
        banner: this.currentGroup,
        index: this.currentSlideIdx,
      });
      this.onClose('onCategoryButtonClick');
    },
    onVideoProgressChange(v) {
      this.progress = v;
      if (v === 100 && this.visible) {
        setTimeout(() => {
          this.goNext();
        });
      }
    },
  },
};
</script>

<style scoped></style>
