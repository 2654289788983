<template>
  <MainLayout with-checkout-reminder with-bottom-toolbar>
    <template v-if="$isWebView" #header>
      <ToolBar
        bottom-toolbar
        :back="onBackClick"
        header-sliding
        @showTop="isButtonsShown = false"
        @hideTop="isButtonsShown = true"
      >
        <p class="break-all truncate">
          {{ catalogName }}
        </p>
        <template #right-side>
          <IconSortingM
            v-if="!loading"
            class="ml-1"
            :modified="!isDefaultSorting"
            @click.native="showSortOptions = true"
          />
        </template>

        <template #bottom-toolbar>
          <div class="px-4 flex items-center">
            <UiInlineIcon
              v-if="isButtonsShown"
              name="back-arrow"
              class="active:opacity-50 focus:outline-none"
              @click="onBackClick"
            />

            <SearchTrigger
              :class="`${isButtonsShown ? 'mx-4' : ''}`"
              disabled
              @click.native="onSearchClick"
            />
            <template v-if="isButtonsShown && !loading">
              <IconSortingM
                :modified="!isDefaultSorting"
                @click.native="showSortOptions = true"
              />
            </template>
          </div>
        </template>
        <template v-if="hasTags" #bottom-toolbar2>
          <div class="flex items-center mt-3">
            <UiInlineIcon
              name="icon-menu"
              class="ml-4 mr-2"
              @click="openTagsMenu"
            />
            <UiChips
              id="tagsChips"
              v-model="selectedTags"
              class="flex items-center overflow-x-scroll hidden-scrollbar rounded-l-xl"
              size="lg"
              text="name"
              :chips="tags"
              @onChange="onChipsChange"
            />
          </div>
        </template>
      </ToolBar>
    </template>
    <template v-else-if="!$isDesktop" #header>
      <WebMobileHeaderCategory
        :tags="tags"
        :has-tags="hasTags"
        :selected-tags="selectedTags"
        :toggle-tags="onChipsChange"
        :open-tags-menu="openTagsMenu"
        has-sort
        v-bind="{
          isDefaultSorting,
        }"
        @onSortClick="showSortOptions = true"
      />
    </template>

    <template v-if="$isDesktop">
      <div class="container flex">
        <div
          class="category-page-title"
          :class="{
            '--cart': $isDesktop && cartItemsSize > 0,
            '--hided': !showCart,
          }"
        >
          <UiBreadCrumbs class="mt-16" :links="breadCrumbs" />
          <div class="mt-8 flex justify-between">
            <h1 v-if="!categorySearchName" class="text-32 font-bold leading-10">
              {{ catalogName }}
              <span
                v-if="totalItems"
                class="ml-5 text-sirius-gray-100 font-medium text-base"
              >
                {{ formatPrice(totalItems) }} {{ productsDeclination }}
              </span>
            </h1>
            <h1 v-else class="text-32 font-bold leading-10">
              {{ categorySearchName }}
            </h1>

            <div class="relative">
              <div
                class="flex items-center cursor-pointer"
                @click="showSortOptions = true"
              >
                <p class="font-medium">
                  {{ sortingText }}
                </p>
                <IconSortingD class="ml-3" :modified="!isDefaultSorting" />
              </div>

              <SortingTooltip
                :show="showSortOptions"
                :selected="selectedSort"
                @update:modelValue="selectedSort = $event.order"
                @close="showSortOptions = false"
              />
            </div>
          </div>
        </div>
        <div v-if="$isDesktop" class="hidden 2xl:block"></div>
      </div>
    </template>

    <div class="container" :class="`${topSpacing} ${loading ? 'h-full' : ''}`">
      <div class="category-page">
        <div class="category-page-content">
          <div v-if="$isDesktop" class="category-page-list" style="">
            <CategoriesList :parent-id="id" :tags="tags" />
          </div>
          <div class="category-page-catalog">
            <transition name="fade" mode="out-in">
              <UiRippleLoader v-if="loading" class="mx-auto" />
              <template v-else>
                <UiInfiniteScroll
                  :is-loading="isNextPageLoading"
                  class="duration-100"
                  @callback="fetchNextPage"
                >
                  <!-- Если наверху баннер с арбузом — не показываем опрос -->
                  <CategoryNpsCategorypageContainer
                    v-if="Object.values(sortedProducts)[0]?.id !== 397"
                  />

                  <NyCategoryBanner v-if="isNYCat" class="mb-4" />

                  <PreheatAlert v-if="needsPreheat" class="mb-4" />

                  <ProductsSearchResult
                    :loading="loading"
                    :categories="sortedProducts"
                    :replacement="isReplacement"
                    :product-deeplink="productDeeplink"
                    :added-from="PRODUCT_ADDED.CATEGORY"
                    :sticky-category-pos="hasTags ? 'toolbar' : 'top'"
                    :is-grid5="!showCart"
                  />
                </UiInfiniteScroll>
              </template>
            </transition>

            <WishButton :show-button="wishButton" />
          </div>
        </div>

        <div
          v-if="$isDesktop && cartItemsSize > 0"
          class="category-page-cart"
          :class="{ '--show': showCart }"
        >
          <ShowCartBtn :show="!showCart" @click.native="showCart = true" />

          <transition name="slide-right">
            <div v-if="showCart" class="w-full duration-300 ease-in">
              <div class="pl-4 w-full flex items-center justify-between">
                <p class="text-2xl font-bold">
                  {{ $t('cart') }}
                </p>
                <HideCartBtn @click.native="showCart = false" />
              </div>
              <MiniCart class="w-full mx-auto mt-7 pl-4" is-mini />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <CategoriesModal
      v-model="showCategoriesModal"
      :selected="selectedTags"
      :tags="originalTags"
      @onModalListChange="onModalListChange"
    />

    <SortingPopup
      :show="showSortOptions && !$isDesktop"
      :selected="selectedSort"
      @close="showSortOptions = false"
      @update:modelValue="selectedSort = $event.order"
    />
  </MainLayout>
</template>

<script>
import { filterCategoryName } from '@services/city/utils/remove-city-from-string';
import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { wordDeclination } from '@shared/utils/delivery/word-declination';
import { mergeCategories } from '@shared/utils/product/merge-categories';
import { groupBySortWeight } from '@shared/utils/product/group-by';
import { eventBus, BUS_EVENTS } from '@shared/utils/event-bus';
import { SaleCategoryService } from '@services/sale-category';
import { formatPrice } from '@shared/utils/formatters/price';
import { createStorage } from '@shared/services/storage';
import { PRODUCT_ADDED } from '@shared/config/product';
import { BannersService } from '@services/banners';
import { mapGetters, mapActions } from 'vuex';
import { RahmetApp } from '@shared/RahmetApp';
import { api } from '@shared/services/api';
import { getFilteredProducts } from '@/lib/coffee/get-filtered-products';
import { sortOptions } from './utils';
import { NY_SPECIAL_CATEGORY } from '@services/ny-contest/config';

import ProductsSearchResult from '@shared/components/product/ProductsSearchResult.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import UiInfiniteScroll from '@ui/UiInfiniteScroll.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import MainLayout from '@layout/MainLayout.vue';
import NyCategoryBanner from '@/views/ny-2024-contest/components/NyCategoryBanner/NyCategoryBanner.vue';

import WebMobileHeaderCategory from '@/components/header/WebMobileHeaderCategory.vue';
import UiBreadCrumbs from '../../shared/components/ui/UiBreadCrumbs.vue';

const storage = createStorage(window.localStorage);

function initialState() {
  return {
    isButtonsShown: false,
    selectedSort: sortOptions[0].order,
    loading: true,
    catalogName: '',
    page: 0,
    maxPage: undefined,
    sortOptions,
    products: {},
    originalTags: [],
    tags: [],
    brands: [],
    selectedTags: [],
    /**
     * todo: удалить всё связанное с брендами, вместо них сортировка
     */
    selectedBrands: [],
    pageCache: {},
    isNextPageLoading: false,
    wishMessage: '',
    wishLoading: false,
    productDeeplink: -1,
    tagDeeplink: {},
    brandDeeplink: {},
    PRODUCT_ADDED,
    tagsTimer: null,
    brandsTimer: null,
    totalItems: 0,
    categorySearchName: '',
    showCart: storage.parse('showCart', true),
    showSortOptions: false,

    showCategoriesModal: false,
  };
}

export default {
  name: 'CategoryView',
  components: {
    WebMobileHeaderCategory,
    ProductsSearchResult,
    UiInfiniteScroll,
    UiRippleLoader,
    SearchTrigger,
    UiBreadCrumbs,
    MainLayout,
    ToolBar,
    NyCategoryBanner,
    CategoryNpsCategorypageContainer: () =>
      import(
        '@components/category-nps/CategoryNPSCategorypageContainer/CategoryNPSCategorypageContainer.vue'
      ),
    SortingTooltip: () =>
      import('./components/SortingTooltip/SortingTooltip.vue'),
    UiInlineIcon: () =>
      import('@pure-ui/components/UiInlineIcon/UiInlineIcon.vue'),
    PreheatAlert: () =>
      import('@shared/../views/category/components/PreheatAlert.vue'),
    IconSortingD: () => import('./components/IconSortingD/IconSortingD.vue'),
    IconSortingM: () => import('./components/IconSortingM/IconSortingM.vue'),
    SortingPopup: () => import('./components/SortingPopup/SortingPopup.vue'),
    WishButton: () => import('@shared/components/catalog/WishButton.vue'),
    CategoriesModal: () => import('./components/CategoriesModal.vue'),
    CategoriesList: () => import('./components/CategoriesList.vue'),
    ShowCartBtn: () => import('./components/ShowCartBtn.vue'),
    HideCartBtn: () => import('./components/HideCartBtn.vue'),
    MiniCart: () => import('../cart/components/miniCart.vue'),
    UiChips: () => import('@ui/UiChips.vue'),
  },
  inject: ['toast', 'bottomSheet'],
  async beforeRouteUpdate(to, from, next) {
    await next();
    this.initializePage();
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === 'category' && to.name === 'main') {
      to.query.top = from.query.top || 0;
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$eventBus.emit('eb_category_page_enter', {
        id: parseInt(vm.id),
        catalogName: vm.catalogName,
      });
    });
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    fetchCategory: {
      type: Function,
      default: api.lavka.categoryInfo,
    },
    fetchCategories: {
      type: Function,
      default: api.lavka.catalogCategories,
    },
    fetchBrands: {
      type: Function,
      default: api.lavka.catalogBrands,
    },
    fetchProducts: {
      type: Function,
      default: api.lavka.catalogProducts,
    },
    route: {
      type: Object,
      default: () => {
        return { name: 'main' };
      },
    },
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters('abtest', ['testGroups']),
    ...mapGetters('cart', ['cartItemsSize']),
    ...mapGetters('delivery', ['warehouseId']),
    ...mapGetters('nyContest', ['nyEnabled']),
    ...mapGetters('component', [
      'categoryPage',
      'categorySort',
      'categoryTags',
      'categoryBrands',
      'categoryMaxPage',
      'categoryTagList',
      'hasCategoryState',
      'categoryProducts',
      'categoryBrandList',
      'isCategoryTagsEmpty',
      'isCategoryBrandsEmpty',
      'isCategoryTagListEmpty',
      'isCategoryProductsEmpty',
      'isCategoryBrandListEmpty',
    ]),
    productsDeclination() {
      if (this.$i18n.locale === 'kk') return 'тауар';
      return wordDeclination(this.totalItems, ['товар', 'товара', 'товаров']);
    },
    breadCrumbs() {
      const links = [{ name: this.$t('main'), path: '/' }];
      if (this.catalogName) {
        links.push({ name: this.catalogName, path: this.$route.path });
      }
      return links;
    },
    hasTags() {
      if (!this.tags) return false;
      return this.tags.length !== 0;
    },
    hasProducts() {
      return !!Object.keys(this.sortedProducts).length;
    },
    sortedProducts() {
      return this.products;
      // if (this.id === '591')
      // return sortProductsBy(this.selectedSort, this.products);
      // return sortProductsBy(this.selectedSort, this.products);
    },
    nextPage() {
      return this.page + 1;
    },
    wishButton() {
      if (!this.maxPage && !this.loading) return true;
      return this.page === this.maxPage && !this.loading;
    },
    isReplacement() {
      if (Boolean(this.$route.query.replacement) === true) {
        // return true;
        return {
          tag: this.$route.query.tag,
          replacement: true,
          product_id: this.$route.query.product_id,
          recipe_id: this.$route.query.recipe_id,
          ...(this.$route.query.feature_id && {
            feature_id: this.$route.query.feature_id,
          }),
        };
      }
      return {
        replacement: false,
      };
    },
    needsPreheat() {
      // "готовая еда"
      return this.id === '451' && this.hasProducts;
    },
    topSpacing() {
      if (this.$isDesktop) return 'mt-10';
      if (!this.$isWebView) return 'mt-28';
      if (this.hasTags) return 'mt-14';
      return 'mt-4';
    },
    /**
     * Флаг, что в данный момент применена сортировка
     * по-умолчанию — популярные
     */
    isDefaultSorting() {
      return this.selectedSort === 'popular_asc';
    },
    sortingText() {
      const labels = {
        popular_asc: {
          kk: 'танымалдарды бірінші',
          ru: 'сначала популярные',
        },
        price_asc: {
          kk: 'арзандарды бірінші',
          ru: 'сначала дешёвые',
        },
        price_desc: {
          kk: 'қымбаттарды бірінші',
          ru: 'сначала дорогие',
        },
      };
      return labels[this.selectedSort][this.$i18n.locale];
    },
    isNYCat() {
      console.log(
        'isNYCat',
        this.nyEnabled,
        this.id,
        NY_SPECIAL_CATEGORY,
        this.nyEnabled && Number(this.id) === NY_SPECIAL_CATEGORY
      );

      return this.nyEnabled && Number(this.id) === NY_SPECIAL_CATEGORY;
    },
  },
  watch: {
    showCart(v) {
      storage.set('showCart', v);
    },
    selectedSort() {
      this.onSortChange(this.selectedSort);
    },
  },
  mounted() {
    eventBus.$on(BUS_EVENTS.LOCALE_CHANGE, this.onLocaleChange);
  },
  created() {
    this.initializePage();
  },
  beforeDestroy() {
    eventBus.$off(BUS_EVENTS.LOCALE_CHANGE);
    clearTimeout(this.tagsTimer);
  },
  methods: {
    formatPrice,
    ...mapActions('component', {
      setCategoryState: 'SET_CATEGORY_STATE',
      resetCategoryState: 'RESET_CATEGORY_STATE',
    }),
    ...mapActions('catalog', {
      fetchMainCategories: 'FETCH_MAIN_CATEGORIES',
    }),
    resetState() {
      Object.assign(this.$data, initialState());
    },
    initializePage() {
      this.resetState();
      this.loading = true;
      this.fetchCategory(this.id).then(
        ({ data }) => (this.catalogName = filterCategoryName(data.name))
      );

      if (this.isCategoryTagsEmpty) {
        this.fetchCategories(this.id).then(({ data }) => {
          this.tags = data.items.map(cat => ({
            ...cat,
            name: filterCategoryName(cat.name),
          }));
          this.originalTags = data.items;
        });
      }

      if (this.isCategoryBrandListEmpty) {
        this.fetchBrands(this.id, this.warehouseId).then(
          ({ data }) => (this.brands = data.items)
        );
      }
      if (this.$route.query.search) {
        this.fetchFromCategorySearch().finally(() => (this.loading = false));
        // случай, когда просто переходим с главной
      } else if (!this.hasCategoryState && !this.$route.query.tag) {
        Promise.all([
          this.fetchFromCatalog(),
          BannersService.loadCategoryBannersToStore(),
        ])
          .then(() => this.controlDeeplink())
          .finally(() => (this.loading = false));
      } else {
        this.controlDeeplink();
      }
    },
    onModalListChange(eventData) {
      const { tags, ...amplitudeData } = eventData;
      Analitycs.logEvent(EVENTS.SUBCATEGORY_SELECTED, {
        from: 'bottomsheet-list',
        ...amplitudeData,
      });
      this.selectedTags = tags;
      this.toggleTags(tags);
    },
    onChipsChange(eventData) {
      const { tags, ...amplitudeData } = eventData;
      Analitycs.logEvent(EVENTS.SUBCATEGORY_SELECTED, {
        from: 'chips',
        ...amplitudeData,
      });
      this.selectedTags = tags;
      this.toggleTags(tags);
    },
    toggleTags(tags) {
      clearTimeout(this.tagsTimer);
      this.tagsTimer = setTimeout(() => {
        this.onTagSelect(tags);
      }, 500);
    },
    onTagSelect(tags) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const tagsCopy = [];
      this.tags.forEach(e => {
        if (tags.includes(e.id)) {
          tagsCopy.unshift(e);
        } else tagsCopy.push(e);
      });
      this.tags = tagsCopy;

      RahmetApp.hapticSelection();

      this.loading = true;
      this.resetData();
      this.selectedTags = tags;

      Analitycs.logSegmentEvent(SEGMENT_EVENTS.FILTER_PRODUCT_LIST, {
        screenName: 'Category Page',
        categoryId: this.id.toString(),
        subcategoryId: this.selectedTags,
        brandId: this.selectedBrands,
        warehouseId: this.warehouseId,
      });
      this.fetchFromCatalogWithTags().finally(() => {
        this.loading = false;
        const tagsChips = document.getElementById('tagsChips');
        if (tagsChips) {
          tagsChips.scroll({
            left: 0,
            behavior: 'smooth',
          });
        }
      });
    },
    toggleBrands(brands) {
      clearTimeout(this.brandsTimer);
      this.brandsTimer = setTimeout(() => {
        this.onBrandSelect(brands);
      }, 500);
    },
    onBrandSelect(brands) {
      RahmetApp.hapticSelection();

      this.loading = true;
      this.resetData();

      this.selectedBrands = brands;

      Analitycs.logEvent(EVENTS.TAB_SUBCAT_CATEGORY, {
        selected_brands: this.selectedBrands,
      });
      Analitycs.logEvent(EVENTS.FILTERED_BY_BRANDS, {
        selected_brands: this.selectedBrands,
      });
      Analitycs.logSegmentEvent(SEGMENT_EVENTS.FILTER_PRODUCT_LIST, {
        screenName: 'Category Page',
        categoryId: this.id.toString(),
        subcategoryId: this.selectedTags,
        brandId: this.selectedBrands,
        warehouseId: this.warehouseId,
      });
      this.fetchFromCatalogWithTags().finally(() => (this.loading = false));
    },
    onSortChange(sort) {
      Analitycs.logEvent(EVENTS.CATEGORY_SORT_APPLY, {
        sorted_by: sort,
      });
      this.resetData();

      this.loading = true;
      const promise =
        this.selectedTags.length === 0 && this.selectedBrands.length === 0
          ? this.fetchFromCatalog()
          : this.fetchFromCatalogWithTags();

      promise.finally(() => (this.loading = false));
    },
    fetchNextPage() {
      const inCache = this.pageCache.hasOwnProperty(this.nextPage);
      if (this.nextPage <= this.maxPage && !inCache) {
        this.pageCache[this.nextPage] = true;
        this.isNextPageLoading = true;

        const promise =
          this.selectedTags.length === 0 && this.selectedBrands.length === 0
            ? this.fetchFromCatalog()
            : this.fetchFromCatalogWithTags();
        promise.finally(() => (this.isNextPageLoading = false));
      }
    },
    async injectPayedSaleProductsIfNeeded() {
      if (
        SaleCategoryService.getParentId() !== Number(this.id) ||
        this.nextPage !== 1
      ) {
        return;
      }

      const payedSaleProducts =
        await SaleCategoryService.fetchPayedSaleCatalog();

      if (payedSaleProducts.length) {
        const temp = groupBySortWeight(payedSaleProducts);
        this.products = mergeCategories(this.products, temp);
      }
    },
    fetchFromCatalog() {
      const payload = {
        parent_id: this.id,
        is_active: 1,
        page: this.nextPage,
        order: this.selectedSort,
        warehouse_id: this.warehouseId,
        ab_groups: this.testGroups,
      };

      return this.fetchProducts(payload)
        .then(async ({ data }) => {
          await this.injectPayedSaleProductsIfNeeded();

          const filteredProducts = getFilteredProducts(data.items);
          const temp = groupBySortWeight(filteredProducts);
          this.products = mergeCategories(this.products, temp);
          this.updatePagination(data.pagination);
          this.totalItems = data.pagination.total_items;

          // data.items.map(el => {
          //   const search = {
          //     '1_c_s_w': el.sort_weight,
          //     '2_c_c_i': el.category_id,
          //     '4_has_brand': el.has_brand,
          //     '5_b_s_w': el.brand_sort_weight,
          //     '6_b_i': el.brand_id,
          //     '7_p_s_w': el.product_sort_weight,
          //     '8_id': el.id,
          //     name: el.name
          //   };
          //   return search;
          // });

          Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
            screenName: 'Category Page',
            categoryId: this.id,
            logId: data.log_id,
            pageNum: this.nextPage - 1,
            warehouseId: this.warehouseId,
          });
        })
        .catch(err => this.toast.show(err.message));
    },
    fetchFromCatalogWithTags() {
      Analitycs.logEvent(EVENTS.VIEWED_SUBCATEGORY, {
        category_ids: this.selectedTags,
        brand_ids: this.selectedBrands,
      });

      const payload = {
        parent_id: this.id,
        is_active: 1,
        category_ids: this.selectedTags,
        brand_ids: this.selectedBrands,
        page: this.nextPage,
        order: this.selectedSort,
        warehouse_id: this.warehouseId,
      };
      return this.fetchProducts(payload)
        .then(async ({ data }) => {
          if (!this.selectedTags.length)
            await this.injectPayedSaleProductsIfNeeded(); // удален для того что б при выбранных чипсах не показывать хиты прождаж

          const filteredProducts = getFilteredProducts(data.items);

          const temp = groupBySortWeight(filteredProducts);
          this.products = mergeCategories(this.products, temp);
          this.updatePagination(data.pagination);
          this.totalItems = data.pagination.total_items;
          Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
            screenName: 'Category Page',
            categoryId: this.id,
            logId: data.log_id,
            pageNum: this.nextPage - 1,
            warehouseId: this.warehouseId,
          });
        })
        .catch(err => this.toast.show(err.message));
    },
    fetchFromCategorySearch() {
      const payload = {
        warehouse_id: this.warehouseId,
        keyword: this.$route.query.search,
        category_id: this.id,
        page: this.nextPage,
        ab_groups: this.testGroups,
      };

      return api.lavka.searchInAllCategory(payload).then(({ data }) => {
        const filteredProducts = getFilteredProducts(data.data.items);
        const temp = groupBySortWeight(filteredProducts);
        this.products = mergeCategories(this.products, temp);
        this.updatePagination(data.data.pagination);
        this.totalItems = data.data.pagination.total_items;
        this.categorySearchName = this.$t('foundItems', {
          totalItems: this.totalItems,
          query: this.$route.query.search,
          productsDeclination: this.productsDeclination,
        });
        Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
          screenName: 'Category Page',
          query: this.$route.query.search,
          categoryId: this.id,
          logId: data.data.log_id,
          warehouseId: this.warehouseId,
        });
      });
    },
    updatePagination(paging) {
      this.page = Number(paging.current_page);
      this.maxPage = paging.total_pages;
    },
    resetData() {
      this.page = 0;
      this.products = {};
      this.pageCache = {};
    },
    onBackClick() {
      Analitycs.logEvent(EVENTS.BACK_CATEGORY);

      if (window.history.length < 3) {
        if (this.$isWebView && window.history.length < 2)
          return RahmetApp.backToRahmetApp();
        return this.$router.push({ name: 'main' });
      }
      return this.$router.back();
    },
    onSearchClick() {
      Analitycs.logEvent(EVENTS.SEARCH_CLICKED, { page: 'category' });
      this.$router.push({
        name: 'search',
        params: this.$route.params.id,
      });
    },
    controlDeeplink() {
      if (this.hasCategoryState) {
        if (!this.isCategoryTagsEmpty) {
          this.selectedTags = this.categoryTags;
          this.selectedTags.forEach(id => {
            this.$set(this.tagDeeplink, id, !this.tagDeeplink[id]);
          });
        }

        if (!this.isCategoryBrandsEmpty) {
          this.selectedBrands = this.categoryBrands;
          this.selectedBrands.forEach(id => {
            this.$set(this.brandDeeplink, id, !this.brandDeeplink[id]);
          });
        }

        if (this.categoryPage !== 0) {
          this.page = this.categoryPage;
        }

        if (!this.isCategoryTagListEmpty) {
          this.tags = this.categoryTagList;
        }

        if (!this.isCategoryBrandListEmpty) {
          this.brands = this.categoryBrandList;
        }

        if (this.categoryMaxPage !== 0) {
          this.maxPage = this.categoryMaxPage;
        }

        if (this.categorySort) {
          this.selectedSort = this.categorySort;
        }

        if (!this.isCategoryProductsEmpty) {
          this.products = this.categoryProducts;
        }
        this.loading = false;
        this.resetCategoryState();
      } else if (this.$route.query.product) {
        this.productDeeplink = Number(this.$route.query.product);
      } else if (this.$route.query.tag) {
        const { tag } = this.$route.query;
        this.selectedTags = [...this.selectedTags, parseInt(tag, 10)];
        this.$set(this.tagDeeplink, tag, !this.tagDeeplink[tag]);
        this.onTagSelect(this.selectedTags);
      } else if (this.$route.query.brand) {
        const { brand } = this.$route.query;
        this.selectedBrands = [...this.selectedBrands, parseInt(brand, 10)];
        this.$set(this.brandDeeplink, brand, !this.brandDeeplink[brand]);
        this.onBrandSelect(this.selectedBrands);
      }
    },
    openTagsMenu() {
      Analitycs.logEvent(EVENTS.ALL_CATEGORY);
      this.showCategoriesModal = true;
    },
    closeTagsMenu() {
      this.showCategoriesModal = false;
    },
    onLocaleChange() {
      this.initializePage();
      this.fetchMainCategories();
    },
  },
  eventBusEvents: {
    eb_on_address_change() {
      this.initializePage();
    },
  },
  i18n: {
    messages: {
      ru: {
        main: 'Главная',
        thereIsNothingIamLookingFor: 'Нет того, что ищу',
        hoursOfDiscounts: 'Часы скидок',
        whatProductsShouldWeAdd:
          'Какие товары нам нужно добавить в ассортимент?',
        provideProductTypeAndBrand: 'Укажите тип товара и бренд',
        pleaseProvideYourWishes: 'Пожалуйста, напишите ваши пожелания',
        cart: 'Корзина',
        foundItems:
          'Найдено {totalItems} {productsDeclination} по запросу «{query}»',
      },
      kk: {
        main: 'Бастапқы',
        thereIsNothingIamLookingFor: 'Мен іздеген нәрсе жоқ',
        hoursOfDiscounts: 'Жеңілдіктер уақыты',
        whatProductsShouldWeAdd: 'Ассортиментке қандай өнімдерді қосу керек?',
        provideProductTypeAndBrand: 'Өнім түрін және брендін көрсетіңіз',
        pleaseProvideYourWishes: 'Өз тілектеріңізді жазыңыз',
        cart: 'Себет',
        foundItems:
          '«{query}» сұранысы бойынша {totalItems} {productsDeclination} табылды ',
      },
    },
  },
};
</script>

<style scoped>
.category-page {
  @apply px-4
    md:px-0 md:flex md:justify-between;
}
.category-page-content {
  @apply w-full flex;
}
.category-page-cart {
  @apply ml-5 relative hidden 3xl:block;
  width: 100%;
  max-width: 120px;
  transform: translateY(-76px);
}
.category-page-cart.--show {
  width: 100%;
  max-width: 360px;
}
.category-page-list {
  @apply mr-9 box-content;
  min-width: 236px;
  width: 236px;
}
.category-page-catalog {
  @apply w-full;
}
.category-page-title {
  @apply w-full;
}

@media (max-width: 1552px) {
  .category-page {
    max-width: 1088px;
  }
  .category-page-catalog {
    max-width: 788px;
  }
  .category-page-title {
    max-width: 1060px;
  }
}

@media (min-width: 1552px) {
  .category-page {
    max-width: unset;
  }
  .category-page-catalog {
    max-width: 988px;
  }
  .category-page-title {
    max-width: 1260px;
  }
  .category-page-title.--cart {
    max-width: calc(100% - 380px);
  }
  .category-page-title.--cart.--hided {
    max-width: calc(100% - 180px);
  }
}
</style>
