<template>
  <div
    class="UiImageSlider ui-image-slider"
    :class="`${!$isDesktop ? '--mob' : ''}`"
  >
    <UiSwiper
      class="UiImageSlider__swiper"
      :slides="imagesComp"
      :settings="$isDesktop ? settings : mobSettings"
      :navigation="$isDesktop"
      custom-key="ui_img_slider"
    >
      <template #slide="image">
        <div class="UiImageSlider__slideContainer">
          <UiAspectRatio ratio="1:1">
            <div v-if="!image.video" class="ui-image-slider-item">
              <img
                v-img-proxy="{ url: image.path, size: 's:430:430' }"
                class="ui-image-slider-item__img"
                :src="image.path"
                alt="Product Image"
              />
            </div>
            <div v-if="image.video" class="ui-image-slider-item">
              <video
                loop
                muted
                autoplay
                playsinline
                style="object-fit: cover"
                :src="image.video.link"
                :poster="image.video.preview_path"
              />
            </div>
          </UiAspectRatio>
        </div>
      </template>

      <template
        v-if="$isDesktop"
        #navigation="{ hasNext, hasPrev, prev, next }"
      >
        <div class="ui-image-slider-navigation">
          <SwiperArrow
            class="ui-image-slider-navigation__arrow"
            :class="`${!hasPrev ? '--disabled' : ''}`"
            variant="gray"
            type="prev"
            @click.native="prev"
          />
          <SwiperArrow
            class="ui-image-slider-navigation__arrow"
            :class="`${!hasNext ? '--disabled' : ''}`"
            variant="gray"
            type="next"
            @click.native="next"
          />
        </div>
      </template>
    </UiSwiper>
  </div>
</template>

<script>
import SwiperArrow from '@pure-ui/components/UiSwiper/components/SwiperArrow.vue';
import NoPhoto from '@img/no-photo.png';
import UiAspectRatio from '@pure-ui/components/UiAspectRatio/UiAspectRatio.vue';
import { hasSupportInlineMedia } from '@/lib/video/has-support-inline-media';

export default {
  name: 'UiImageSlider',
  components: {
    SwiperArrow,
    UiAspectRatio,
    UiSwiper: () => import('@pure-ui/components/UiSwiper/UiSwiper.vue'),
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    video: {
      type: [Object, Boolean],
      default: null,
    },
  },
  data() {
    return {
      fallback: NoPhoto,
      settings: {
        slidesPerView: 1,
        loop: false,
        centeredSlides: true,
        pagination: {
          clickable: true,
          textAlign: 'left',
        },
      },
      mobSettings: {
        slidesPerView: 1,
        loop: false,
        centeredSlides: true,
        pagination: {
          clickable: true,
        },
      },
    };
  },
  computed: {
    imagesComp() {
      const result = [...this.images];
      if (this.video && this.hasSupportInlineMedia) {
        result.unshift({ video: this.video });
      }
      if (!result.length) {
        result.push({ path: this.fallback });
      }
      return result;
    },
    count() {
      return this.imagesComp.length;
    },
    hasSupportInlineMedia() {
      return hasSupportInlineMedia();
    },
  },
  methods: {},
};
</script>

<style scoped>
.ui-image-slider {
  --swiper-pagination-bottom: 12px;
}

.ui-image-slider-item {
  @apply pb-13 h-full flex justify-center items-center cursor-pointer;
}
.ui-image-slider-item__img {
  @apply w-full h-full object-cover rounded-3xl;
}

.ui-image-slider-navigation {
  @apply absolute z-10 right-0 bottom-0
   flex items-center space-x-3;
}
.ui-image-slider-navigation__arrow {
  @apply h-11 w-11;
}
.--disabled {
  @apply opacity-30;
}

.ui-image-slider.--mob {
  --swiper-pagination-bottom: 12px;
  --swiper-pagination-color: #acaaba;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #f1f2f7;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 8px;
}
.--mob .ui-image-slider-item {
  @apply pb-0;
  max-height: 430px;
  margin: auto;
}
.--mob .ui-image-slider-item__img {
  @apply p-0 rounded-none;
  max-height: 430px;
}

.UiImageSlider__slideContainer {
  max-width: 430px;
  margin: auto;
}
</style>
