<template>
  <div v-if="categories.length" class="SpecialCategoires">
    <UiNavigationTitle
      v-secret-click="toggleConsoleButton"
      class="px-4 md:px-0"
      main
    >
      {{ $t('title') }}
    </UiNavigationTitle>

    <UiSwiper
      v-if="show && $isDesktop"
      custom-key="special_cat"
      :slides="categories"
      :settings="settings"
      navigation
      class="special-category-swiper"
    >
      <template #slide="category">
        <SpecialCategory
          class="w-full"
          :category="category"
          :parent-id="specialCategories.id"
        />
      </template>
    </UiSwiper>
    <div v-else class="special-category-swiper-mob hidden-scrollbar">
      <div
        v-for="(category, index) in categories"
        :key="`special_cat_${index + 1}_${category.id}`"
        class="special-category-swiper-mob-item"
      >
        <SpecialCategory
          :category="category"
          :parent-id="specialCategories.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { shuffleArray } from '@/utils/functions/shuffle-array';
import { getFilteredSpecialCategories } from '@/lib/coffee/get-filtered-special-categories';
import SecretClick from '@/utils/directives/secret-click';

import UiNavigationTitle from '@ui/UiNavigationTitle.vue';
import SpecialCategory from './SpecialCategory.vue';
import { DevConfigs } from '@/utils/dev-config';

export default {
  name: 'SpecialCategoires',
  directives: { SecretClick },
  components: {
    UiNavigationTitle,
    SpecialCategory,
    UiSwiper: () => import('@pure-ui/components/UiSwiper/UiSwiper.vue'),
  },
  data() {
    return {
      categories: [],
      settings: {
        slidesPerView: 3,
      },
      timer: null,
      show: true,
    };
  },
  computed: {
    ...mapState('catalog', ['specialCategories']),
    ...mapGetters('delivery', ['warehouseId']),
  },
  watch: {
    specialCategories: {
      immediate: true,
      deep: true,
      handler(v) {
        // вот этот костыль для того
        // что б свайпер нормально обновлялся при изменений слайдов
        // возможно нужно пересмотреть когда будет добавлять скелетоны
        this.showAgain();
        const l = v.categories || [];

        const isFixed = v => String(v.sort_weight).startsWith('777');
        const fixed = l.filter(v => isFixed(v));
        const shuffled = shuffleArray(l.filter(v => !isFixed(v)));

        // Объединяем fixed и shuffled
        let filteredCategories = [...fixed, ...shuffled];
        if (this.$isWebView) {
          filteredCategories = filteredCategories.filter(
            e => e.id !== 797 && e.id !== 798
          );
        }

        // удаляем категорию цветы на Самале DevConfigs.isProd ? 1478 : 1439
        filteredCategories = filteredCategories.filter(e =>
          DevConfigs.isProd ? e.id !== 1478 : e.id !== 1439
        );

        // Фильтруем по наличию продукта и присваиваем результат в this.categories
        this.categories = getFilteredSpecialCategories(
          filteredCategories
        ).filter(e => e.has_product);
      },
    },
  },

  methods: {
    toggleConsoleButton() {
      this.$store.commit('logger/TOGGLE_BUTTON_VISIBILITY');
    },
    showAgain() {
      this.show = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.show = true;
      }, 0);
    },
  },
  i18n: {
    messages: {
      ru: { title: 'Специальные предложения' },
      kk: { title: 'Арнайы ұсыныстар' },
    },
  },
};
</script>

<style scoped>
.special-category-swiper {
  @apply mt-5;
  height: 152px;
}
.special-category-swiper-mob {
  @apply mt-3 pl-4 w-full
  flex items-center overflow-scroll;
}
.special-category-swiper-mob-item {
  @apply mr-2 last:border-r-8 border-transparent;
}
</style>
