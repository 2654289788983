<template>
  <div class="HowToEarn">
    <h3>{{ $t('title') }}</h3>
    <div class="HowToEarn__table">
      <b v-html="$t('scoreOne')" />
      <span v-html="$t('order3k')" />
      <b v-html="$t('scoreTwo')" />
      <span v-html="$t('order4k')" />
      <b v-html="$t('scoreFive')" />
      <span v-html="$t('order5k')" />
    </div>
    <div v-if="$store.getters['nyContest/additionalCategory'].enabled">
      <div class="HowToEarn__additional">{{ $t('additionalScore') }}</div>
      <div class="HowToEarn__additionalBlock">
        <div class="HowToEarn__table2">
          <b v-html="$t('scoreOne')" />
          <span>
            <span v-html="$t('forAnyProductFromCategory1')" />
            <router-link
              :to="{
                name: 'category',
                params: {
                  id: $store.getters['nyContest/additionalCategory'].categoryId,
                },
              }"
              class="HowToEarn__category"
            >
              {{ $store.getters['nyContest/additionalCategory'][$i18n.locale] }}
            </router-link>
            <span v-html="$t('forAnyProductFromCategory2')" />
          </span>
        </div>
      </div>
      <p class="HowToEarn__note">
        <span v-html="$t('note1order1score')" />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HowToEarn',
  i18n: {
    messages: {
      kk: {
        title: 'Ұпайлар қалай жиналады',
        scoreOne: '1&nbsp;ұпай',
        scoreTwo: '2&nbsp;ұпай',
        scoreFive: '5&nbsp;ұпай',
        order3k: '3000&nbsp;₸&nbsp;– 4000&nbsp;₸ сомасындағы тапсырысқа',
        order4k: '4001&nbsp;₸&nbsp;– 5000&nbsp;₸ сомасындағы тапсырысқа',
        order5k: '5000&nbsp;₸ мен одан да көп сомадағы тапсырысқа',
        additionalScore: 'қосымша ұпайлар',
        forAnyProductFromCategory1: `3000 ₸ басталатын тапсырыс кезінде`,
        forAnyProductFromCategory2: `категориясындағы кез келген тауар үшін беріледі`,
        note1order1score: '* 1 тапcырыс үшін тек 1 қосымша ұпай беріледі;',
      },
      ru: {
        title: 'Как заработать баллы',
        scoreOne: '1&nbsp;балл',
        scoreTwo: '2&nbsp;балла',
        scoreFive: '5&nbsp;баллов',
        order3k: 'за&nbsp;заказ на&nbsp;сумму 3000&nbsp;₸&nbsp;– 4000&nbsp;₸',
        order4k: 'за&nbsp;заказ на&nbsp;сумму 4001&nbsp;₸&nbsp;– 5000&nbsp;₸',
        order5k: 'за&nbsp;заказ на&nbsp;сумму 5001&nbsp;₸&nbsp;и более',
        additionalScore: 'дополнительные баллы',
        forAnyProductFromCategory1: `за покупку любого товара из&nbsp;категории`,
        forAnyProductFromCategory2: `при&nbsp;заказе от&nbsp;3000&nbsp;₸`,
        note1order1score:
          '* За&nbsp;1&nbsp;заказ можно получить только 1&nbsp;дополнительный балл.',
      },
    },
  },
};
</script>

<style scoped>
.HowToEarn__note {
  color: #009164;
}

.HowToEarn__table {
  font-size: 14px;
  line-height: 18px;
  padding-top: 16px;
  display: grid;
  grid-template-columns: 80px auto;
  gap: 12px 8px;
}

.HowToEarn__additional {
  padding-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #009164;
}

.HowToEarn__table2 {
  padding-top: 16px;
  font-size: 14px;
  line-height: 18px;
  padding-top: 16px;
  display: grid;
  grid-template-columns: 80px auto;
  gap: 12px 8px;
}

.HowToEarn__category {
  color: #009164;
  font-weight: 500;
}

.HowToEarn__additionalBlock {
  display: flex;
}

@media screen and (min-width: 768px) {
  .HowToEarn__table {
    font-size: 16px;
    line-height: 20px;
  }

  .HowToEarn__table2 {
    font-size: 16px;
    line-height: 20px;
  }

  .HowToEarn__additional {
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
