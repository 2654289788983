<template>
  <UiButtonNew
    class="ExpandButton"
    :height="$isDesktop ? 'xl' : 'lg'"
    :round="$isDesktop ? '3xl' : '2xl'"
    size="none"
    variant="primary"
    v-on="$listeners"
  >
    <div class="w-6"></div>
    {{ $i18n.locale === 'kk' ? 'Тізімді толық көру' : 'Раскрыть весь список' }}
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15L18 21L24 15"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </UiButtonNew>
</template>

<script>
import UiButtonNew from '@ui/UiButtonNew.vue';

export default {
  name: 'ExpandButton',
  components: { UiButtonNew },
};
</script>

<style scoped>
.ExpandButton {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .ExpandButton {
    font-size: 20px;
    line-height: 24px;
    border-radius: 24px;
  }
}
</style>
