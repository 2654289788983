<template>
  <div class="NyDesktopReminder" @click="onClick">
    <ReminderText :score="$store.getters['nyContest/cartScore']" />
    <IconInfo />
  </div>
</template>

<script>
import ReminderText from './components/ReminderText/ReminderText.vue';
import IconInfo from './components/IconInfo/IconInfo.vue';

export default {
  name: 'NyDesktopReminder',
  components: { ReminderText, IconInfo },
  methods: {
    onClick() {
      this.$eventBus.emit('eb_open_ny_conditions_modal');
      // this.$router.push({
      //   name: 'ny-contest-2024',
      //   query: { from: 'mobile-reminder' },
      // });
    },
  },
};
</script>

<style scoped>
.NyDesktopReminder {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
}
</style>
