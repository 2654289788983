import { DevConfigs } from '@/utils/dev-config';
import { CatalogService } from '@services/catalog';

export const SpecialCategoryModule = {
  namespaced: true,
  state: {
    specialCategoryList: [],
  },
  getters: {
    specialCategoryList: state => {
      return state.specialCategoryList;
    },
  },
  mutations: {
    SET_CATEGORY_ITEMS(state, value) {
      state.specialCategoryList = value;
    },
  },
  actions: {
    FETCH_SPECIAL_CATEGORY({ commit, rootGetters }) {
      const categoryId = DevConfigs.isProd ? 1478 : 1439;
      const warehouseId = rootGetters['delivery/warehouseId'];
      const abGroups = rootGetters['abtest/testGroups'];
      const payload = {
        parent_id: categoryId,
        is_active: 1,
        page: 1,
        order: 'popular_asc',
        warehouse_id: 5,
        ab_groups: abGroups,
      };
      if (warehouseId === 5) {
        return CatalogService.getProductsByCategoryId(payload).then(data => {
          commit('SET_CATEGORY_ITEMS', data.items);
        });
      }
    },
  },
};
