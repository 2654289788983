<template>
  <section class="ContestConditions">
    <div class="ContestConditions__container">
      <NyPageSubtitle
        id="ny-conditions"
        class="ContestConditions__title mb-6 md:mb-16"
      >
        {{ $t('title') }}
      </NyPageSubtitle>

      <ContestBanners class="mb-14 md:mb-15" />
      <div class="ContestConditions__body">
        <template v-if="!$isDesktop">
          <HowToEarn class="ContestConditions__howToEarn mb-12" />
          <WinnersBlock class="mb-12" />
          <ResultsBlock class="mb-14" />
          <NyBlockquote class="">
            <span v-html="$t('blockquote')"></span>
          </NyBlockquote>
        </template>

        <div v-if="$isDesktop" class="ContestConditions__desk">
          <div class="ContestConditions__columnsWrap">
            <div class="ContestConditions__col">
              <WinnersBlock class="" />
              <HowToEarn />
            </div>
            <div class="ContestConditions__col relative">
              <ResultsBlock />
            </div>

            <div class="ContestConditions__colToy"></div>
            <div class="ContestConditions__colToy2"></div>
          </div>
          <NyBlockquote class="ContestConditions__blocqoute">
            <span v-html="$t('blockquote')"></span>
          </NyBlockquote>
        </div>
      </div>

      <div class="ContestConditions__img"></div>
    </div>
  </section>
</template>

<script>
import HowToEarn from '@/views/ny-2024-contest/components/ContestConditions/components/HowToEarn/HowToEarn.vue';
import WinnersBlock from '@/views/ny-2024-contest/components/ContestConditions/components/WinnersBlock/WinnersBlock.vue';
import ResultsBlock from '@/views/ny-2024-contest/components/ContestConditions/components/ResultsBlock/ResultsBlock.vue';
import NyPageSubtitle from '../NyPageSubtitle/NyPageSubtitle.vue';
import NyBlockquote from './components/NyBlockquote/NyBlockquote.vue';
import ContestBanners from './components/ContestBanners/ContestBanners.vue';
export default {
  name: 'ContestConditions',
  components: {
    NyBlockquote,
    ContestBanners,
    NyPageSubtitle,
    HowToEarn,
    WinnersBlock,
    ResultsBlock,
  },
  i18n: {
    messages: {
      ru: {
        title: 'Условия розыгрыша',
        blockquote:
          'Чем больше заказов — тем&nbsp;больше шансов выиграть ценные призы ✨',
      },
      kk: {
        title: 'Ұтыс шарттары',
        blockquote:
          'Тапсырыс көбірек болған сайын, бағалы сыйлықтар ұту мүмкіндігі де көбірек ✨',
      },
    },
  },
};
</script>

<style scoped>
.ContestConditions {
  @apply px-4;
  background: #e9eeed;
}

.ContestConditions__container {
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 1188px;
  font-size: 16px;
  line-height: 24px;
}

.ContestConditions__body >>> h3 {
  margin-top: 24px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.ContestConditions__body >>> p {
  margin-top: 16px;
  font-size: 16px;
  line-height: 20px;
}

.ContestConditions__img {
  position: absolute;
  top: -50px;
  right: 43px;
  width: 69px;
  height: 69px;
  background: url('@/assets/images/ny-2024-contest/snow.png') center center
    no-repeat;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .ContestConditions {
    overflow: visible;
  }

  .ContestConditions__columnsWrap {
    @apply relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    max-width: 988px;
    margin: auto;
  }
  .ContestConditions__title {
    padding: 0 92px;
  }
  .ContestConditions__body {
    position: relative;
  }

  .ContestConditions__desk {
    @apply relative;
  }
  .ContestConditions__body >>> h3 {
    margin-top: 0;
    font-size: 20px;
    line-height: 24px;
  }

  .ContestConditions__body >>> p {
    font-size: 16px;
    line-height: 24px;
  }
  .ContestConditions__blocqoute {
    @apply mt-38;
  }
  .ContestConditions__colToy {
    background: url('./images/toy.png') center center no-repeat;
    background-size: contain;
    width: 128px;
    height: 128px;
    transform: translate(-119px, -78px);
    position: absolute;
    left: -126px;
    top: 34px;
  }
  .ContestConditions__colToy2 {
    background: url('./images/toy2.png') center center no-repeat;
    background-size: contain;
    width: 529px;
    height: 337px;
    position: absolute;
    bottom: -135px;
    right: -44px;
  }

  .ContestConditions__img {
    top: -72px;
    right: auto;
    left: 560px;
    width: 100px;
    height: 100px;
  }
}
</style>
