<template>
  <div class="CustomProductList">
    <HorizontalProductList
      :navigation="navigation"
      :products="products"
      :added-from="PRODUCT_ADDED.PROMO_BANNER_ON_MAIN"
    >
      <template #title>
        {{ $t('title') }}
      </template>
    </HorizontalProductList>
  </div>
</template>

<script>
import { DevConfigs } from '@/utils/dev-config';
import { PRODUCT_ADDED } from '@shared/config/product';
import HorizontalProductList from '@shared/components/horizontal-products/HorizontalProductList.vue';

export default {
  name: 'CustomProductsList',
  components: {
    HorizontalProductList,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      PRODUCT_ADDED,
    };
  },
  computed: {
    navigation() {
      return {
        name: 'category',
        params: {
          id: DevConfigs.isProd ? '1478' : '1439',
        },
      };
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Букеты для вашего повода',
      },
      kk: {
        title: 'Сіздегі себепке арналған букеттер',
      },
    },
  },
};
</script>
