<template>
  <div class="ContentBlock">
    <NyPageSubtitle id="ny-results" class="ContentBlock__title" color="black">
      {{ $t('title') }}
    </NyPageSubtitle>
    <ul class="ContentBlock__list">
      <li class="ContentBlock__item">
        <div class="ContentBlock__itemValue">
          {{ score }}
        </div>
        <div class="ContentBlock__itemLabel" v-html="$tc('earned', score)" />
      </li>
      <li class="ContentBlock__item">
        <div class="ContentBlock__itemValue">
          {{ place > 999 ? '999+' : place }}
        </div>
        <div class="ContentBlock__itemLabel" v-html="$t('place')" />
      </li>
    </ul>

    <h3
      class="ContentBlock__conditions"
      v-html="$t(participate ? 'lotteryParticipate' : 'lotteryNotParticipate')"
    ></h3>

    <UiButton
      class="ContentBlock__btnTable"
      :size="$isDesktop ? 'XL' : 'LG'"
      @click="$emit('onScrollTo')"
    >
      <div class="w-6"></div>
      <span v-html="$t('goToTable')" />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 16L14 12L10 8"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </UiButton>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import NyPageSubtitle from '@/views/ny-2024-contest/components/NyPageSubtitle/NyPageSubtitle.vue';

export default {
  name: 'ContentBlock',
  components: {
    UiButton,
    NyPageSubtitle,
  },
  props: {
    score: {
      type: Number,
      default: 0,
    },
    place: {
      type: Number,
      default: 0,
    },
    participate: Boolean,
  },
  i18n: {
    messages: {
      kk: {
        title: 'Менің нәтижелерім',
        earned: 'ұпай жиналды',
        place: 'Рейтингтегі орын',
        lotteryParticipate: `
          Cіз 50 ұпайдан артық жинадыңыз, енді бағалы
          сыйлықтар ойнына қатысасыз ✨
        `,
        lotteryNotParticipate: `
          Бағалы сыйлықтар ойнына қатысу үшін 50
          және одан да көп ұпай жинау керек
        `,
        goToTable: 'Ұпайлар кестесіне өту',
      },
      ru: {
        title: 'Мои результаты',
        earned:
          'балло<br/>заработано | балл<br/>заработан | балла<br/>заработано | баллов<br/>заработано',
        place: 'место в&nbsp;рейтинге',
        lotteryParticipate: `
          Вы набрали более 50&nbsp;баллов,
          и&nbsp;участвуете в&nbsp;розыгрыше ценных призов&nbsp;✨
        `,
        lotteryNotParticipate: `
          Чтобы участвовать в&nbsp;розыгрыше ценных призов,
          наберите&nbsp;50 и&nbsp;более баллов
        `,
        goToTable: 'Перейти к таблице',
      },
    },
  },
};
</script>

<style scoped>
.ContentBlock {
  padding: 24px 24px 32px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 1px 8px 24px 0px rgba(110, 83, 52, 0.2);
}

.ContentBlock__title {
  @apply mb-6;
}

.ContentBlock__list {
  @apply pb-3;
  border-bottom: 3px solid #f1f2f7;
}

.ContentBlock__item {
  @apply mb-4;
  display: grid;
  grid-template-columns: 84px 130px;
  gap: 8px;
}

.ContentBlock__itemValue {
  color: var(--main-black, #ffa100);
  font-size: 36px;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
}

@supports (-webkit-background-clip: text) and
  (-webkit-text-fill-color: transparent) {
  .ContentBlock__itemValue {
    background: url('./images/foil.png') left top / 84px auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.ContentBlock__conditions {
  @apply mt-5 leading-6;
}

.ContentBlock__itemLabel {
  color: var(--text-text-additional, #acaaba);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.ContentBlock__lottery {
  margin-top: 28px;
  padding-top: 20px;
  border-top: 3px solid #f1f2f7;

  color: var(--text-text-primary, #242424);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.ContentBlock__btnTable {
  @apply mt-6;
}

@media screen and (min-width: 768px) {
  .ContentBlock {
    border-radius: 64px;
    padding: 64px 100px;
    padding-right: 237px;
  }

  .ContentBlock__list {
    padding-top: 56px;
    display: flex;
    gap: 0 112px;
  }

  .ContentBlock__item {
    grid-template-columns: auto 241px;
    gap: 16px;
  }

  .ContentBlock__itemValue {
    font-size: 72px;
    line-height: 72px;
  }

  .ContentBlock__itemLabel {
    font-size: 28px;
    line-height: 32px;
  }

  .ContentBlock__lottery {
    padding-top: 32px;
    margin-top: 40px;
  }

  .ContentBlock__lotteryText {
    max-width: 435px;
  }

  .ContentBlock__btnTable {
    max-width: 382px;
    margin-top: 64px;
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
