import { api } from '@shared/services/api';
import { Analitycs, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { store } from '@/store';

function fetchFromApi(params = {}) {
  return api.lavkaFavorites
    .favoritesProductsList(params)
    .then(r => r.data)
    .then(data => {
      const newIds = data.items.map(v => v.product_id);
      const oldIds = store.getters['favorites/favoritesIds'];
      const uupdatedIds = [...new Set([...newIds, ...oldIds])];
      store.commit('favorites/SET_FAVORITES', uupdatedIds);
      return Promise.all([data.items, data.pagination, data.log_id]);
    })
    .then(([products, pagination, logId]) => {
      return {
        products,
        pagination,
        logId,
      };
    });
}

// function moveUnavailableToEnd(products) {
//   const available = products.filter(v => v.max_quantity > 0);
//   const unavailable = products.filter(v => !v.max_quantity);
//   return [...available, ...unavailable];
// }

export function fetchFavoritesFromApi(params) {
  return fetchFromApi(params);
}

export function logSegmentListView(logId, pageNum) {
  Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
    screenName: 'Favorites Page',
    logId,
    pageNum,
  });
}
