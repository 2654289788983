<template>
  <div class="ContestPrizes">
    <div class="ContestPrizes__container">
      <NyPageSubtitle id="ny-prizes">{{ $t('title') }}</NyPageSubtitle>
      <ul class="ContestPrizes__list">
        <li
          v-for="(prize, i) in visiblePrizes"
          :key="i"
          class="ContestPrizes__listItem"
        >
          <PrizeItem
            :image="prize.image"
            :title="prize.title[$i18n.locale]"
            :place="prize.place[$i18n.locale]"
            :is-additional="i === 0"
          />
        </li>
      </ul>

      <UiButton
        v-if="!showAll"
        class="ContestPrizes__btnExpand"
        :height="$isDesktop ? 'xl' : 'lg'"
        :round="$isDesktop ? '3xl' : '2xl'"
        size="none"
        variant="primary"
        @click="showAll = true"
      >
        {{ $t('btnExpand') }}
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 15L18 21L24 15"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </UiButton>
      <div class="ContestPrizes__toy"></div>
    </div>
  </div>
</template>

<script>
import UiButton from '@ui/UiButtonNew.vue';
import NyPageSubtitle from '../NyPageSubtitle/NyPageSubtitle.vue';
import PrizeItem from './components/PrizeItem/PrizeItem.vue';
import { prizes } from './constants';

export default {
  name: 'ContestPrizes',
  components: {
    NyPageSubtitle,
    PrizeItem,
    UiButton,
  },
  data: () => ({ prizes, showAll: false }),
  computed: {
    visiblePrizes() {
      return this.showAll ? this.prizes : this.prizes.slice(0, 6);
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Призы',
        btnExpand: 'Раскрыть весь список',
      },
      kk: {
        title: 'Сыйлықтар',
        btnExpand: 'Тізімді толық көру',
      },
    },
  },
};
</script>

<style scoped>
.ContestPrizes {
  background: #e9eeed;
}

.ContestPrizes__container {
  width: calc(100% - 32px);
  max-width: 988px;
  margin: auto;
}

.ContestPrizes__list {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.ContestPrizes__listItem:first-child {
  @apply bg-white p-2 rounded-3xl;
}

.ContestPrizes__btnExpand {
  margin-top: 32px;
  width: 100%;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .ContestPrizes__container {
    position: relative;
  }

  .ContestPrizes__list {
    padding-top: 48px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 56px 90px;
  }

  .ContestPrizes__btnExpand {
    margin-top: 80px;
    max-width: 332px;
    font-size: 20px;
  }
  .ContestPrizes__toy {
    position: absolute;
    height: 128px;
    width: 128px;
    top: 43px;
    left: calc(-128px - 94px);
    background: url('./images/toy.png') center center no-repeat;
    background-size: contain;
  }
}
</style>
