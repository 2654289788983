<template>
  <div class="NyMobileReminder" @click="onClick">
    <div class="NyMobileReminder__text">
      <ReminderText />
    </div>
  </div>
</template>

<script>
import ReminderText from './components/ReminderText/ReminderText.vue';

export default {
  name: 'NyMobileReminder',
  components: {
    ReminderText,
  },
  methods: {
    onClick() {
      this.$eventBus.emit('eb_open_ny_conditions_modal', {
        from: 'mobile-reminder',
      });
    },
  },
};
</script>

<style scoped>
.NyMobileReminder {
  @apply px-4;
  height: 64px;
  border-radius: 24px;
  width: 100%;
  text-align: center;
  background: url('./images/background.png') center top / 100% auto;
}

.NyMobileReminder__text {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding-top: 4px;
  padding-bottom: 8px;
}
</style>
