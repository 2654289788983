<template>
  <div>
    <SpecialCategorySlider
      v-if="showCategorySlider && !$isDesktop"
      :products="productsForSlider"
    ></SpecialCategorySlider>
  </div>
</template>

<script>
import SpecialCategorySlider from '@pure-ui/components/SpecialCategorySlider/SpecialCategorySlider.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SpecialCategorySliderWrapper',
  components: { SpecialCategorySlider },
  computed: {
    ...mapGetters('specialCategory', ['specialCategoryList']),
    ...mapGetters('delivery', ['warehouseId']),
    showCategorySlider() {
      return this.warehouseId === 5 && this.specialCategoryList.length > 0;
    },
    productsForSlider() {
      return this.specialCategoryList.slice(0, 3);
    },
  },
};
</script>

<style scoped></style>
